/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar, ScriptableContext } from "react-chartjs-2";
import {
  basicChart,
  chartOptions
} from "variables/my_charts.js";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";

import LineChart from "views/charts/LineChart.js"
import BarChart from "views/charts/BarChart.js"
import {element, func} from "prop-types";
import ProjectDetails from "./ProjectDetails";
import MultiLineChart from "../views/charts/MultiLineChart"
import Sidebar from "../components/Sidebar/Sidebar";
import {Suspense} from "react";
import InsightsContainer from "./grid/InsightsContainer";
import InsightsLargeGrid from "./grid/InsightsLargeGrid";
import {getPeriod} from '../utils/TimeframeUtil'
import {Helmet} from "react-helmet";
import InfoView from "./InfoView";


require("./DeveloperReportContainer.css")
// function CommitsContainer() {
const DeveloperReportContainer = ({period, timespan}) => {
    const [elements, setElements] = React.useState([]);
    const [firstElements, setFirstElements] = React.useState([]);
    const [nextElements, setNextElements] = React.useState([]);
    const [lastUpdateDateTime, setLastUpdateDateTime] = React.useState(undefined);
    const [renderAll, setRenderAll] = React.useState(false);

    React.useEffect(() => {
        const fetchData = async () => {
            const headers = new Headers();
            headers.append('authority', "cryptometheus.com");
            fetch("https://cryptometheus.com/".concat("api/crypto/projects/lastUpdateDateTime?apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf&v=123456"),
                {headers: headers})
                .then(response => response.text())
                .then(data => setLastUpdateDateTime(data.replaceAll("\"", "")))
        }
        fetchData();
    }, []);


    React.useEffect(() => {
        const fetchData = async () => {
            let url = timespan === 3 ? "api/crypto/dev/activity/first-chunks/light" : "api/crypto/dev/activity/first-chunks";
            const headers = new Headers();
            headers.append('authority', "cryptometheus.com");
            fetch("https://cryptometheus.com/".concat(url + "?apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf&v=123456&timeframe=" + period),
                {headers: headers})
                .then(response => response.json())
                .then(data => setFirstElements(data))
        }
        fetchData();

        if (renderAll) {
            setRenderAll(false);
            update();
        }
    }, [period]);


    React.useEffect(() => {
        setElements(elements.concat(firstElements))
    }, [firstElements]);

    React.useEffect(() => {
        setElements(elements.concat(nextElements))
    }, [nextElements]);

    function update() {
        if (renderAll === false) {
            let url = timespan === 3 ? "api/crypto/dev/activity/next-chunks/light" : "api/crypto/dev/activity/next-chunks";
            const fetchData = async () => {
                const headers = new Headers();
                headers.append('authority', "cryptometheus.com");
                const response = await fetch("https://cryptometheus.com/".concat(url + "?apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf&v=123456&timeframe=" + period),
                    {headers: headers});
                // convert the data to json
                const json = await response.json();
                setNextElements(json);
            }
            setRenderAll(true);
            fetchData();
        }
    }

    Date.prototype.getMonthName = function () {
        let monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        return monthNames[this.getMonth()];
    }

    return (
        <>
            <div className="content">
                <Helmet>
                    <title>{"Top Cryptocurrencies Ranked by Development Activity | Developer report - " + new Date().getMonthName() + " " + new Date().getFullYear()}</title>
                    <meta name="description"
                          content={"Discover the top cryptocurrencies by developer activity." +
                              " Stay up-to-date with the latest blockchain technologies and track the progress of your favorite coins."}/>
                    <meta name="keywords"
                          content={"Top cryptocurrencies, developer activity, Github commits, Developer report, Crypto Github stats, Cryptocurrency progress, Crypto development"}/>
                    {/*<link rel="canonical" href={"https://cryptometheus.com/report"}/>*/}
                </Helmet>
                <Row className="landingPage">
                    <div className="title title-developer-report">
                        Uncover the Top Cryptocurrencies with the Most Active Development and Github Activity
                        - {new Date().getMonthName() + " " + new Date().getFullYear()}
                    </div>
                    <div className="lastupdate content-center">
                        Last Updated: {lastUpdateDateTime}
                    </div>
                </Row>
                <Row>
                    <InsightsLargeGrid elements={elements} changeRenderAll={update}/>
                </Row>
                <Row>
                    <InfoView/>
                </Row>
            </div>
        </>
    );
}

export default DeveloperReportContainer;
