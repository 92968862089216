/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar, ScriptableContext } from "react-chartjs-2";
import {
  barChartOptions
} from "variables/my_charts.js";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col, Table
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";
import ProjectDetails from "../../views/ProjectDetails";

import {getPeriod} from '../../utils/TimeframeUtil'


require("./SimpleTable.css");
const SimpleTable = ({elements, openLink, period}) => {

  return (
      <>
        <Card className="card-plain">
          <CardBody>
            <Table className="tablesorter sidebar-table" responsive>
              <thead className="text-primary">
              <tr>
                {/*<th className="first-th">#</th>*/}
                <th id="sidebar-element">Project</th>
                {/*<th>Ticker</th>*/}
                <th id="sidebar-element" className="sidebar-header">
                  Commits ({getPeriod(period).label})
                </th>
              </tr>
              </thead>
              <tbody>
              {
                elements.map(({Index, Name, Ticker, ProjectTotalCommits}) => (
                    <tr key={Ticker+"-tr"}>
                      {/*<td id="first-column" className="first-th">*/}
                      {/*  <div id="index-area">*/}
                      {/*    <p id="index-p">{Index}</p>*/}
                      {/*  </div>*/}
                      {/*</td>*/}
                      <td id="projectsTableItem" key={Ticker} onClick={function () {
                            if(openLink === true){
                              window.open("/project/" + Ticker, "_self");
                            }else {
                              const element = document.getElementById("project-" + Ticker);
                              const y = element.getBoundingClientRect().top + window.pageYOffset - 80;
                              const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
                              var mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
                              if (mac) {
                                // document.getElementById("project-" + Ticker).scrollIntoView();
                                window.scrollTo({top: y, behavior: 'smooth'});
                              } else {
                                console.log("---NativeSmoothScroll not supported---")
                                // window.scrollTo({top: y});
                                document.getElementById("project-" + Ticker).scrollIntoView(false);
                              }
                            }
                      }}
                      >
                        <Row>
                        <Col className="logo-table">
                            <img style={{"width": "30px"}}
                                alt={Ticker} src={"https://cryptometheus.com/".concat("api/logos/").concat(Ticker).concat(".png")}/>
                        </Col>
                        <Col>
                          <Row id="td-name-element-1">
                            <div id="index-area" style={{width: Index/10 * 1.1 + 17 + 'px'}}>
                              <p id="index-p">{Index}</p>
                            </div>
                            {Ticker}
                          </Row>
                          <Row id="td-name-element-2">
                            {Name}
                          </Row>
                        </Col>
                        </Row>
                        {/*<img style={{"width": "30px"}}*/}
                        {/*     src={"https://cryptometheus.com/".concat("api/logos/").concat(Ticker).concat(".png")}/> {Ticker}*/}
                      </td>
                      {/*<td>{Ticker}</td>*/}
                      <td id="sidebar-element" className="second-column" key={Ticker+"-1"} onClick={function () {
                        if(openLink === true){
                          window.open("/project/" + Ticker, "_self");
                        }else {
                          const element = document.getElementById("project-" + Ticker);
                          const y = element.getBoundingClientRect().top + window.pageYOffset - 80;
                          const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
                          var mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
                          if (mac) {
                            // document.getElementById("project-" + Ticker).scrollIntoView();
                            window.scrollTo({top: y, behavior: 'smooth'});
                          } else {
                            console.log("---NativeSmoothScroll not supported---")
                            // window.scrollTo({top: y});
                            document.getElementById("project-" + Ticker).scrollIntoView(false);
                          }
                        }
                      }}>
                        {ProjectTotalCommits}
                        {/*<div id="commits-area">*/}
                        {/*  <p id="commits-p">{ProjectTotalCommits}</p>*/}
                        {/*</div>*/}
                      </td>
                    </tr>
                ))
              }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </>
  );
}

export default SimpleTable;
