/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

import PieChart from "../../views/charts/PieChart";
import SimplePieChart from "../../views/charts/SimplePieChart";
import {Card, CardTitle, Col, Row, Table} from "reactstrap";
import InfoView from "../InfoView";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

require("./TokenomicsContainer.css")
function TokenomicsContainer(props) {
    const location = useLocation();
    const pathParams = location.pathname.split("/");
    const [elements, setElements] = React.useState([]);

    React.useEffect(() => {
        let fetchData = async () => {
            const headers = new Headers();
            headers.append('authority', "cryptometheus.com");
            const response = await fetch("https://cryptometheus.com/".concat("api/crypto/tokenomics?apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf"),
                {headers: headers});
            // convert the data to json
            const json = await response.json();
            setData(json);
        }

        const project = pathParams.length > 1 ? pathParams[2] : undefined;
        if (project) {
            fetchData = async () => {
                const headers = new Headers();
                headers.append('authority', "cryptometheus.com");
                const response = await fetch("https://cryptometheus.com/".concat("api/crypto/tokenomics/token?key=" + project + "&apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf"),
                    {headers: headers});
                // convert the data to json
                const json = await response.json();
                let array = [];
                array.push(json)
                setData(array);
            }
        }

        fetchData()
            .catch(console.error);
    }, [props.period]);

    function setData(data) {
        let elements = [];
        data.forEach(element => {
            if (element.distributionList != undefined) {
                let labels = [];
                let values = [];
                element.distributionList.forEach(distribution => {
                    labels.push(distribution.name);
                    values.push(distribution.percentage)
                });
                element.distributionLabels = labels;
                element.distributionValues = values;
            }
            elements.push(element);
        });
        setElements(elements);
    }

    return (
        <> {elements != undefined && elements.length === 1 ?
            <Helmet>
                <title>{"Explore Tokenomics and Token Distribution for " + elements[0].tokenName + ", " + elements[0].ticker}</title>
                <meta name="description"
                      content={"Explore the tokenomics of " + elements[0].tokenName + ", " + elements[0].ticker + " and gain insights into their token distribution patterns." +
                          " Our site offers in-depth analysis and charts to help you make informed investment decisions."}/>
                <meta name="keywords"
                      content={elements[0].tokenName + ", " + elements[0].ticker + ", tokenomics, token distribution, cryptocurrency, analysis, charts, blockchain, digital assets, developer activity"}/>
                {/*<link rel="canonical" href={"https://cryptometheus.com/tokenomics/" + elements[0].ticker.toUpperCase()}/>*/}
            </Helmet> :
            <Helmet>
                <title>{"Explore Tokenomics and Token Distribution for Top Cryptocurrencies"}</title>
                <meta name="description"
                      content={"Explore the tokenomics of various cryptocurrencies and gain insights into their token distribution patterns." +
                          " Our site offers in-depth analysis and charts to help you make informed investment decisions."}/>
                <meta name="keywords"
                      content={"tokenomics, token distribution, cryptocurrency, analysis, charts, blockchain, digital assets, developer activity"}/>
                {/*<link rel="canonical" href={"https://cryptometheus.com/tokenomics"}/>*/}
            </Helmet>
        }
            <div className="project-title title-tokenomics">
                <h1 className="title-text">
                    {elements != undefined && elements.length === 1 ?
                        "Explore Tokenomics and Token Distribution for " + elements[0].tokenName + ", " + elements[0].ticker:
                            "Explore Tokenomics and Token Distribution for Top Cryptocurrencies"
                    }
                </h1>
            </div>
            <div className="tokenomics-container content">
                {
                    elements
                        .map(({
                                  tokenName,
                                  ticker,
                                  distributionLabels,
                                  distributionValues,
                                  distributionList
                              }) => (
                            <div key={ticker}>
                                {distributionList != undefined && distributionLabels.length > 0 ?
                                    <div id={"project-" + ticker}>
                                        <CardTitle>
                                            <a href={"/project/" + ticker}
                                               className={"token-name"}>
                                                <img className={"token-name"} style={{"width": "30px"}}
                                                     src={"https://cryptometheus.com/".concat("api/logos/").concat(ticker).concat(".png")}
                                                     alt={ticker}/>{tokenName}, {ticker}
                                            </a>
                                        </CardTitle>

                                        <Row className={"tokenomics-item"}>
                                            <Col>
                                                <SimplePieChart labels={distributionLabels}
                                                                data={distributionValues}></SimplePieChart>
                                            </Col>
                                            <Col>
                                                <Table className="tablesorter overflow-hidden" responsive>
                                                    <thead className="text-primary">
                                                    <tr>
                                                        <th></th>
                                                        <th>Details</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        distributionList.map(({name, description}) => (
                                                            <tr key={name}>
                                                                <td>
                                                                    {name}
                                                                </td>
                                                                <td>
                                                                    {description}
                                                                </td>
                                                            </tr>


                                                        ))
                                                    }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </div> : null}
                            </div>

                        ))
                }
                <Row>
                    <InfoView/>
                </Row>
            </div>
        </>
    );
}



export default TokenomicsContainer;
