/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import {Helmet} from "react-helmet";

// react plugin used to create charts
import { Line, Bar, ScriptableContext } from "react-chartjs-2";
import {
  basicChart,
  chartOptions
} from "variables/my_charts.js";


// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";

import LineChart from "views/charts/LineChart.js"
import BarChart from "views/charts/BarChart.js"
import {func} from "prop-types";
import ProjectDetailsV2 from "./ProjectDetailsV2";
import {useLocation} from "react-router-dom";
import {getPeriod} from '../utils/TimeframeUtil'
import InfoView from "./InfoView";
import PriceToCommit from "./price/PriceToCommit";

require("./Project.css");

const Project= ({timeframe, timespan}) => {
    const location = useLocation();
    const [element, setElement] = React.useState(undefined);
    const [notFound, setNotFound] = React.useState(false);

    React.useEffect(() => {
        const pathParams = location.pathname.split("/");
        const project = pathParams.length > 1 ? pathParams[2] : undefined;
        if (project) {
            const headers = new Headers();
            headers.append('authority', "cryptometheus.com");
            fetch("https://cryptometheus.com/".concat("api/crypto/project?key=" + encodeURIComponent(project) + "&apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf&v=1234" +
                    // fetch("https://cryptometheus.com/".concat("api/crypto/project?key=" + encodeURIComponent(project) + "&apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf&v=1234" +
                    "&timeframe=" + timeframe),
                {headers: headers})
                .then(response => response.json())
                .then(data => setData(data))
                .catch(error => setNotFound(true));
        }
    }, [location, timeframe]);


    function setData(data) {
        setElement(data);
    }

    const getNumberOfRepositories = (totalCommits, totalRepositories)=> {
        return Math.min(totalCommits, totalRepositories);
    }

    const getNumberOfCoreRepositories = (totalCommits, totalRepositories, totalDevs)=> {
        var max = Math.max(totalCommits, totalRepositories, totalDevs);
        var min = Math.min(totalCommits, totalRepositories, totalDevs);
        return (max / min).toFixed(0);
    }


    return (
        <>

            <div className="project-title">
                {element ?
                    <h1 className="title-text">Tracking development activity for {element.Name}</h1> : null
                }

            </div>

            {
                element ? <Helmet>
                    <title>🚀 Exciting Development Updates! Explore Developer Activity for {element.Name} ({element.Ticker})</title>
                    <meta name="description" content={`Dive into the dynamic developer world of ${element.Name} (${element.Ticker})!
                     Uncover valuable insights on developer activity, metrics, and the vibrant coding landscape of ${element.Name}. Let the data tell the story of innovation! 🚀`} />
                    <meta name="keywords"
                          content={element.Ticker + ", " + element.Name + ", " + "GitHub, commits, development, developer report, chart"}/>
                    <meta name="twitter:description"
                          content={"Collecting metrics from " + element.Name + " GitHub repositories to gain insight on " + element.Ticker + ", " + element.Name + " developer activity"}/>
                    <meta property="og:image" content={"https://cryptometheus.com/api/logos/"+element.Ticker+".png"} />
                    {/*<link rel="canonical" href={"https://cryptometheus.com/project/" + element.Ticker.toUpperCase()}/>*/}
                </Helmet> : null
            }
            <div id="single-project" className="content">
                {element && element.Insight ?
                    <div className="project-description">
                        <Card className="card-chart">
                        <p className={"project-desc"}><img style={{"width": "20px"}} className="project-logo"
                                alt={element.Ticker}
                                src={"https://cryptometheus.com/".concat("api/logos/").concat(element.Ticker).concat(".png")}/>
                            {element.Name} demonstrated {element.ProjectTotalCommits > 100 ? "strong" : "modest"} developer activity on
                            GitHub with <b>{element.ProjectTotalCommits}</b> GitHub commits across <b>{getNumberOfRepositories(element.ProjectTotalCommits, element.NumberOfRepository)}</b> core
                            repositories for the selected
                            period. This earned the project a ranking of <u><b><font
                                color={"blue"}>{element.Index}{element.Index%10===1?"st":(element.Index%10===2?"nd":(element.Index%10===3?"rd":"th"))}</font></b></u> place.
                            Furthermore, over the previous 7 days,
                            there {element.Insight.WeeklyCoreDevs == 1 ? "was" : "were"} <b>{element.Insight.WeeklyCoreDevs}</b> core developers who
                            made <b>{element.Insight.WeeklyCoreCommits}</b> commits
                            across <b>{getNumberOfRepositories(element.Insight.WeeklyCoreCommits, element.Insight.WeeklyCoreDevs)}</b> core repositories in GitHub.
                            The number of commits is on
                            the {element.Insight.WeeklyCoreCommits1MChange < 0 ? "downtrend" : "uptrend"} with a monthly
                            change of <font
                                color={element.Insight.WeeklyCoreCommits1MChange > 0 ? "GREEN" : "RED"}><b>{element.Insight.WeeklyCoreCommits1MChange}%</b></font>.
                        </p>
                        <p className={"project-desc"}><img style={{"width": "20px"}} className="project-logo"
                                alt={element.Ticker}
                                src={"https://cryptometheus.com/".concat("api/logos/").concat(element.Ticker).concat(".png")}/> {element.Name} ecosystem
                            {element.Insight.EcosystemProjects > 10 ? " continues to flourish" : " is still at an early stage"},
                            with <b>{element.Insight.EcosystemProjects}</b> {element.Insight.EcosystemProjects == 1 ? "project" : "projects"} building
                            on its network, with a total of <b>{element.Insight.EcosystemRepos}</b> public GitHub
                            repositories.
                            Moreover, during this week, initiatives based on {element.Name} observed the engagement
                            of <b>{element.Insight.WeeklyEcosystemDevs}</b> individual developers,
                            a <font
                                color={element.Insight.WeeklyEcosystemActiveDevs1MChange > 0 ? "GREEN" : "RED"}><b>{element.Insight.WeeklyEcosystemActiveDevs1MChange}%</b></font> change
                            compared to the previous month.
                            These developers have made <b>{element.Insight.WeeklyEcosystemCommits}</b> commits contributions,
                            which represents
                            a {element.Insight.WeeklyEcosystemCommits1MChange > 0 ? "increase" : "decrease"} of <font
                                color={element.Insight.WeeklyEcosystemCommits1MChange > 0 ? "GREEN" : "RED"}><b>{element.Insight.WeeklyEcosystemCommits1MChange}%</b></font> in
                            in contrast to the previous month.
                        </p>
                        </Card></div> : null
                }
                {element && element.Insight ?
                    <div className="project-summary-container">
                        <h4  className="dev-report">{element.Name} - Developer Report Summary</h4>
                        <Card className="project-summary">
                            <table className="project-summary-table">
                                <thead>
                                <tr>
                                    <th rowSpan="3">
                                        <img style={{"width": "20px"}} className="project-logo"
                                             alt={element.Ticker}
                                             src={"https://cryptometheus.com/".concat("api/logos/").concat(element.Ticker).concat(".png")}/>
                                        {element.Name}
                                    </th>
                                    <th className="project-summary-table" rowSpan="3">Projects</th>
                                    <th className="project-summary-table" rowSpan="3">Repos</th>
                                    <th className="project-summary-table header-back" colSpan="6">Weekly GitHub stats</th>
                                </tr>
                                <tr>
                                    <th className="project-summary-table blue-back" colSpan="3">Commits</th>
                                    <th className="project-summary-table purple-back" colSpan="3">Developers</th>
                                </tr>
                                <tr>
                                    <th className="project-summary-table blue-back" colSpan="1">Count</th>
                                    <th className="project-summary-table blue-back" colSpan="1">1M%</th>
                                    <th className="project-summary-table blue-back" colSpan="1">3M%</th>
                                    <th className="project-summary-table purple-back" colSpan="1">Count</th>
                                    <th className="project-summary-table purple-back" colSpan="1">1M%</th>
                                    <th className="project-summary-table purple-back" colSpan="1">3M%</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th className="project-summary-table2">Core</th>
                                    <td>{element.NumberOfProjects}</td>
                                    <td>{element.NumberOfRepository}</td>
                                    <td className="blue-back">{element.Insight.WeeklyCoreCommits}</td>
                                    <td className="blue-back"><font
                                        color={element.Insight.WeeklyCoreCommits1MChange > 0 ? "GREEN" : "RED"}>{
                                        element.Insight.WeeklyCoreCommits1MChange}%</font>
                                    </td>
                                    <td className="blue-back"><font
                                        color={element.Insight.WeeklyCoreCommits3MChange > 0 ? "GREEN" : "RED"}>
                                        {element.Insight.WeeklyCoreCommits3MChange}%</font>
                                    </td>
                                    <td className="purple-back">{element.Insight.WeeklyCoreDevs}</td>
                                    <td className="purple-back"><font
                                        color={element.Insight.WeeklyCoreActiveDevs1MChange > 0 ? "GREEN" : "RED"}>
                                        {element.Insight.WeeklyCoreActiveDevs1MChange}%</font>
                                    </td>
                                    <td className="purple-back"><font
                                        color={element.Insight.WeeklyCoreActiveDevs3MChange > 0 ? "GREEN" : "RED"}>
                                        {element.Insight.WeeklyCoreActiveDevs3MChange}%</font>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="project-summary-table2">Ecosystem</th>
                                    <td>{element.Insight.EcosystemProjects}</td>
                                    <td>{element.Insight.EcosystemRepos}</td>
                                    <td className="blue-back">{element.Insight.WeeklyEcosystemCommits}</td>
                                    <td className="blue-back"><font
                                        color={element.Insight.WeeklyEcosystemCommits1MChange > 0 ? "GREEN" : "RED"}>
                                        {element.Insight.WeeklyEcosystemCommits1MChange}%</font>
                                    </td>
                                    <td className="blue-back"><font
                                        color={element.Insight.WeeklyEcosystemCommits3MChange > 0 ? "GREEN" : "RED"}>
                                        {element.Insight.WeeklyEcosystemCommits3MChange}%</font>
                                    </td>
                                    <td className="purple-back">{element.Insight.WeeklyEcosystemDevs}</td>
                                    <td className="purple-back"><font
                                        color={element.Insight.WeeklyEcosystemActiveDevs1MChange > 0 ? "GREEN" : "RED"}>
                                        {element.Insight.WeeklyEcosystemActiveDevs1MChange}%</font>
                                    </td>
                                    <td className="purple-back"><font
                                        color={element.Insight.WeeklyEcosystemActiveDevs3MChange > 0 ? "GREEN" : "RED"}>
                                        {element.Insight.WeeklyEcosystemActiveDevs3MChange}%</font>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Card>
                    </div> : null

                }
                {element ?
                    <>
                    <PriceToCommit project={element.Ticker} displayMatrix={true} displayMatrix={false}/>
                    <a href={`https://cryptometheus.com/crypto/${element.Ticker}`} rel="canonical">Click to Explore Our {element.Name} ({element.Ticker}) Price Prediction Tool</a>
                    </>:null
                }
                    {element ?
                    <div>
                        <h4 className="header-project-details">Tracking core development activity for {element.Name}</h4>
                        <ProjectDetailsV2
                            timespan={timespan}
                            key={element.Ticker}
                            index={element.Index}
                            name={element.Name}
                            ticker={element.Ticker}
                            totalCommits={element.ProjectTotalCommits}
                            labelsD={element.Commits.DailyFrame.Labels}
                            dataD={element.Commits.DailyFrame.Commits}
                            labelsW={element.Commits.WeeklyFrame.Labels}
                            dataW={element.Commits.WeeklyFrame.Commits}
                            labelsM={element.Commits.MonthlyFrame.Labels}
                            dataM={element.Commits.MonthlyFrame.Commits}
                            repos={element.Repositories}
                            gitUrl={element.GitUrl}
                            numberOfRepository={element.NumberOfRepository}
                            numberOfContributors={element.NumberOfContributors}
                            languages={element.Languages}
                            fullscreen={true}
                            displayTimespanButtons={true}
                            period={timeframe}
                        />
                    </div> : null
                }
                <InfoView/>
            </div>
        </>
    );
}

export default Project;
