/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";
import {
    Card, Col, Row, UncontrolledTooltip
} from "reactstrap";
import {func} from "prop-types";

require("./InsightsSmallGrid.css")
const InsightsSmallGrid = ({elements, title, img, updating, changeRenderAll}) => {
    const [data, setData] = useState([]);
    const [largeData, setLargeData] = useState([]);
    const [smallData, setSmallData] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const [spinning, setSpinning] = useState(false);


    React.useEffect(() => {
        setSpinning(updating);
    }, [updating]);

    React.useEffect(() => {
        setLargeData(elements);
        setSmallData(elements.slice(0, 5));
        if (elements.length > 10) {
            setSpinning(false);
        }
        if(collapsed){
            setData(elements);
        }else {
            setData(elements.slice(0, 5));
        }
    }, [elements]);

    function collapse(activate) {
        setCollapsed(activate);
        if (activate === true) {
            setData(largeData);
        } else {
            setData(smallData);
        }
    }

    return (
        <Card className="insights-card" onMouseOver={()=>{ if(changeRenderAll != undefined) changeRenderAll(true)}}>
            <Row>
                <Col>
                    <h2 className="insight-grid-title">Weekly GitHub Stats - <b>{title}</b></h2>
                </Col>
                {spinning === true ?
                    <Col className="insight-grid-title updating">
                        Updating
                        <img className="small-icon spinning-wheel" alt="..."
                             src={require("assets/img/icons/spinning.gif")}/>
                    </Col> : null}
            </Row>
            <table className="insights-table">
                <thead>
                <tr>
                    <th colSpan="1" className="table-align">
                        <img className="small-icon" alt="..."
                             src={img}/>
                    </th>
                    <th colSpan="1" className="right-small">Project</th>
                    <th colSpan="1" className="table-align right">Commits</th>
                    <th colSpan="1" className="table-align right mobile-none">1M%</th>
                    <th colSpan="1" className="table-align right mobile-none">3M%</th>
                    <th className="table-align right">Developers</th>
                    <th colSpan="1" className="table-align right mobile-none">1M%</th>
                    <th colSpan="1" className="table-align right mobile-none">3M%</th>
                </tr>
                </thead>
                <tbody>
                {
                    data
                        .map(({
                                  Index,
                                  Name,
                                  Ticker,
                                  WeeklyCoreCommits,
                                  WeeklyCoreCommits1MChange,
                                  WeeklyCoreCommits3MChange,
                                  WeeklyCoreDevs,
                                  WeeklyCoreDevs1MChange,
                                  WeeklyCoreDevs3MChange
                              }) => (
                            <tr className="tr-insights" key={Ticker}>
                                <td className="table-align index">{Index}</td>
                                <td className="right-nano project-column"
                                    onClick={function () {
                                        window.open("/project/" + Ticker);
                                    }}>
                                    <img style={{"width": "20px"}} className="small-icon right-icon"
                                         alt={Ticker}
                                         src={"https://cryptometheus.com/".concat("api/logos/").concat(Ticker).concat(".png")}/>
                                    {Name.length < 10 ? Name : Ticker}
                                </td>
                                <td className="table-align right"><b>{WeeklyCoreCommits}</b></td>
                                <td className="table-align right mobile-none">
                                    <div className={WeeklyCoreCommits1MChange > 0 ? "color-green" : "color-red"}>
                                        {WeeklyCoreCommits1MChange}%
                                    </div>
                                </td>
                                <td className="table-align right edge-col mobile-none">
                                    <div className={WeeklyCoreCommits3MChange > 0 ? "color-green" : "color-red"}>
                                        {WeeklyCoreCommits3MChange}%
                                    </div>
                                </td>
                                <td className="table-align right"><b>{WeeklyCoreDevs}</b></td>
                                <td className="table-align right mobile-none">
                                    <div className={WeeklyCoreDevs1MChange > 0 ? "color-green" : "color-red"}>
                                        {WeeklyCoreDevs1MChange}%
                                    </div>
                                </td>
                                <td className="table-align right mobile-none">
                                    <div className={WeeklyCoreDevs3MChange > 0 ? "color-green" : "color-red"}>
                                        {WeeklyCoreDevs3MChange}%
                                    </div>
                                </td>
                            </tr>
                        ))
                }
                </tbody>
            </table>
            <Row>
            <div id="share-report" className="small-grid-details-link0" onClick={() => {window.open("/report", "_blank")}}>
                <img className="small-icon" alt="share"
                     src={require("assets/img/icons/share.png")}
                />
            </div>
                <UncontrolledTooltip target="#share-report">
                    View detailed report
                </UncontrolledTooltip>
            </Row>
            <Row>
            <div id="more-coins" className="small-grid-details-link" onClick={() => collapse(!collapsed)}>
                <img className="small-icon" alt="..."
                     src={collapsed === true ?
                         require("assets/img/icons/colapse.png") :
                         require("assets/img/icons/expand.png")}/>
            </div>
                <UncontrolledTooltip target="#more-coins">
                    View more coins
                </UncontrolledTooltip>
            </Row>
        </Card>
    );
};

export default InsightsSmallGrid;
