/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar, ScriptableContext } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";
import LanguageLabels from "../labels/LanguagesLabels";
import {func} from "prop-types";
import CryptoMatrix from "../price/PriceMatrix";

require("./MultiLineChartV2.css");
const ForecastLineChart = ({name, ticker, statsMap}) => {
  const [values, setValues] = React.useState([]);
  const [labels, setLabels] = React.useState([]);
  const [percentages, setPercentages] = React.useState([]);
  const [datasets, setDatasets] = React.useState([]);
  const [forecastLabels, setForecastLabels] = useState([]);
  const [forecastPrices, setForecastPrices] = useState([]);
  const [finalPrices, setFinalPrices] = useState([]);

  const [forecastStatsMap, setForecastStatsMap] = useState([]);
  const [volatilityIndex, setVolatilityIndex] = useState(1);
  const [diminishingReturnsOption, setDiminishingReturnsOption] = useState("1");
  const [diminishingReturnsFactor, setDiminishingReturnsFactor] = useState(1);
  const [numberOfPoints, setNumberOfPoints] = useState(96);
  const [numberOfPointsOption, setNumberOfPointsOption] = useState("96");

  const [decayFactor, setDecayFactor] = useState(undefined);

  useEffect(() => {
    if(values.length > 0) {
      const max = Math.max(...values);
      const min = Math.min(...values);
      const factor = min / max * 100;
      if(factor > 10){
        setDecayFactor(1);
      }
      else if(factor > 5){
        setDecayFactor(0.95);
      }
      else if(factor > 2){
        setDecayFactor(0.9);
      }
      else if (factor > 1){
        setDecayFactor(0.85);
      }
      else if (factor > 0.8){
        setDecayFactor(0.8);
      }
      else if (factor > 0.6){
        setDecayFactor(0.75);
      }
      else if (factor > 0.4){
        setDecayFactor(0.7);
      }
      else if (factor > 0.3){
        setDecayFactor(0.65);
      }
      else {
        setDecayFactor(0.6)
      }
    }
  }, [values]);

  const handleChange = (event) => {
    setVolatilityIndex(parseInt(event.target.value));
  };

  const handleChangeNumberOfPoints = (event) => {
    setNumberOfPoints(parseInt(event.target.value));
    setNumberOfPointsOption(event.target.value);
  };

  const handleChangeDiminishingReturnsFactor = (event) => {
    setDiminishingReturnsOption(event.target.value);
    let index = parseInt(event.target.value);
    switch (index) {
      case 1: setDiminishingReturnsFactor(1);
      break
      case 2: setDiminishingReturnsFactor(0.8);
      break
      case 3: setDiminishingReturnsFactor(0.6);
      break
      case 4: setDiminishingReturnsFactor(0.4);
    }
  };

  function calculateMovingAverage(array, windowSize) {
    const result = [];
    for (let i = 0; i < array.length; i++) {
      const start = Math.max(0, i - Math.floor(windowSize / 2));
      const end = Math.min(array.length, i + Math.ceil(windowSize / 2));
      const sum = array.slice(start, end).reduce((acc, val) => acc + val, 0);
      result.push(sum / (end - start));
    }
    return result;
  }

  useEffect(() => {
    let smoothedPrices = calculateMovingAverage(forecastPrices, volatilityIndex);
    smoothedPrices = applyDiminishingReturns(smoothedPrices, diminishingReturnsFactor);
    setFinalPrices(smoothedPrices);
  }, [volatilityIndex, forecastPrices]);


  function applyDiminishingReturns(initialArray, factor) {
    const array = [...initialArray];
    const length = array.length;
    for (let i = 0; i < length - 1; i++) {
      var currentMax = Math.max(...array.slice(0, i));
      if (i / 48 > 1) {//not first cycle
        if (array[i] > currentMax) {
          array[i] = array[i] * factor
        } else {
          // array[i] = array[i] * (1 + factor * i);
        }
        if (array[i] > currentMax * 2) {
          array[i] = array[i] / 3;
        }
        if (array[i] < currentMax / 2) {
          array[i] = array[i] * 1.5;
        }
      }
    }
    return array;
  }

  useEffect(() => {
    let diminishedArray = applyDiminishingReturns(forecastPrices, diminishingReturnsFactor);
    diminishedArray = calculateMovingAverage(diminishedArray, volatilityIndex);
    setFinalPrices(diminishedArray);
  }, [diminishingReturnsFactor, forecastPrices]);



  const randomProp = [
    0.967, 0.952, 0.887,
    0.832, 0.804, 0.828,
    0.932, 0.978, 0.944, 0.919, 0.936, 0.974,
    0.711, 0.784,
    0.881, 0.867, 0.916, 0.946, 0.903, 0.922, 0.945,
    0.929, 0.882, 0.952,
    0.726, 0.764,
    0.825,
    0.903, 0.881, 0.855, 0.889, 0.809, 0.823,
    0.955, 0.901, 0.899, 0.956, 0.932, 0.926, 0.945, 0.893, 0.878,
    0.881, 0.867, 0.916, 0.946, 0.903, 0.922, 0.945
  ];
  function getStringMonth(month, year) {
    switch (month) {
      case 'JAN': return `JAN ${year}`;
      case 'FEB': return `FEB ${year}`;
      case 'MAR': return `MAR ${year}`;
      case 'APR': return `APR ${year}`;
      case 'MAY': return `MAY ${year}`;
      case 'JUN': return `JUN ${year}`;
      case 'JUL': return `JUL ${year}`;
      case 'AUG': return `AUG ${year}`;
      case 'SEP': return `SEP ${year}`;
      case 'OCT': return `OCT ${year}`;
      case 'NOV': return `NOV ${year}`;
      case 'DEC': return `DEC ${year}`;
      default: return `DEC ${year}`;
    }
  }


  function getNextMonth(month) {
    switch (month) {
      case 'IAN': return 'FEB';
      case 'FEB': return 'MAR';
      case 'MAR': return 'APR';
      case 'APR': return 'MAY';
      case 'MAY': return 'JUN';
      case 'JUN': return 'JUL';
      case 'JUL': return 'AUG';
      case 'AUG': return 'SEP';
      case 'SEP': return 'OCT';
      case 'OCT': return 'NOV';
      case 'NOV': return 'DEC';
      case 'DEC': return 'IAN';
      default: return 'DEC';
    }
  }


  useEffect(() => {
    // Load data from localStorage on component mount
    const savedForecastPrices = JSON.parse(localStorage.getItem('forecastPrices'+name));
    if (savedForecastPrices) {
      setForecastPrices(savedForecastPrices);
    }
  }, []);

  useEffect(() => {
    // Save forecastPrices to localStorage whenever it changes
    localStorage.setItem('forecastPrices'+name, JSON.stringify(forecastPrices));
  }, [name]);

  useEffect(() => {
    if(statsMap) {
      setForecastStatsMap(statsMap);
      const labels = [];
      const values = [];
      let percentages = [];

      const keys = Object.keys(statsMap);
      keys.forEach((key, index) => {
        if(keys.length > 50 && index < 10){
          return;
        }
        labels.push(key);
        var stats = statsMap[key];
        values.push(stats.PriceOpen)
        var priceChange = (stats.PriceClose - stats.PriceOpen) / stats.PriceOpen;
        if(priceChange > 2){
          priceChange = 2;
        }
        if(priceChange < -2){
          priceChange = -2;
        }
        percentages.push(priceChange)
      })
      setLabels(labels);
      setValues(values);
      while (percentages.length < 48) {
        percentages = percentages.concat([0.2, 0.02, 0.23, 0.17, 0.3, -0.2, -0.06, -0.19, 0.05, -0.31, 0.18]);
      }
      percentages = percentages.slice(0, 48);
      setPercentages(percentages);
    }
  },[statsMap]);

  function getRandomNumberBetween(a, b) {
    if (a > b) {
      [a, b] = [b, a];
    }
    return Math.random() * (b - a) + a;
  }


  const getPriceChange = (index) => {
    let i = index % 48;
    let prc = percentages[i];
    prc = prc * randomProp[i];
    if (i % 5 == 0) {
      if (prc < 0) {
        prc = prc + 0.05;
      } else {
        prc = prc - 0.05;
      }
    }
    return prc;
  }


  useEffect(() => {
    if (values.length > 0 && labels.length > 0 && decayFactor) {
      const numForecastPoints = numberOfPoints; // Forecasting until DEC 2030 (12 months/year * 8 years)

      const currentLabel = labels[labels.length - 1];
      let currentMonth = currentLabel.split(' ')[0];
      let currentYear = parseInt(currentLabel.split(' ')[1], 10);
      const newLabels = Array.from({length: numForecastPoints}, (_, index) => {
        if (currentMonth === 'DEC') {
          currentYear += 1;
        }
        currentMonth = getNextMonth(currentMonth);

        return getStringMonth(currentMonth, currentYear);
      });

      let currentPrices = [...values];
      var newPrices = [values[values.length - 1]];
      let volatility = 0;
      let cycleIndex = 0;
      let diminishingReturnsF = 0.02;
      let reductionFactor = 0.02;
      let cycleMap = {};
      cycleMap[0] = {};
      cycleMap[0].min = Math.max(...values);
      cycleMap[0].max = Math.min(...values);
      for (let index = 0; index <= numForecastPoints; index++) {
        const ci = Math.floor(index / 48);
        if (ci !== cycleIndex) {
          cycleIndex = ci;
          cycleMap[cycleIndex] = {};
          cycleMap[cycleIndex].min = 100000000;
          cycleMap[cycleIndex].max = 0;
          // Decrease the impact of volatility and downtrend as cycleIndex increases
          volatility += 0.005 * (cycleIndex + 1); // You can adjust this factor
          diminishingReturnsF = diminishingReturnsF * diminishingReturnsF;
          reductionFactor += 0.02;
        }

        const lastPrice = currentPrices[currentPrices.length - 1];

        let prcChange = getPriceChange(index);
        if(prcChange > 0){
          prcChange = prcChange * decayFactor * Math.max(0.7, (1 - cycleIndex * 0.05));
        }
        let priceIncrease = prcChange + 1;

        if (priceIncrease > 1) {
          priceIncrease = priceIncrease - 0.01 * volatility;
          priceIncrease = (1 - diminishingReturnsF) * priceIncrease;
          priceIncrease = priceIncrease;
          // priceIncrease = 1 - reductionFactor > 1 ? (1 - reductionFactor) * priceIncrease : priceIncrease;
        } else {
          priceIncrease = priceIncrease + 0.01 * volatility + 0.005;
          priceIncrease = (1 + diminishingReturnsF) * priceIncrease;
          // priceIncrease = priceIncrease + reductionFactor < 1 ? priceIncrease + reductionFactor : priceIncrease;
        }

        let price = lastPrice * priceIncrease;

        if (cycleIndex > 1) {
          if (price < cycleMap[cycleIndex - 1].min) {
            price = price + (cycleMap[cycleIndex - 1].min - price) * Math.pow(0.2, index);
          }
        }
        if (price < cycleMap[cycleIndex].min) {
          cycleMap[cycleIndex].min = price;
        }

        if (price > cycleMap[cycleIndex].max) {
          cycleMap[cycleIndex].max = price;
        }

        const np = [...currentPrices, price];
        currentPrices = np;
        newPrices.push(price);
      }

      setForecastLabels([...labels, ...newLabels]);
      setForecastPrices(newPrices);
    }
  }, [values, labels, diminishingReturnsFactor, numberOfPoints, decayFactor]);


  useEffect(() => {
    if(finalPrices.length > 0 && forecastLabels && labels.length > 1) {
      updateDataSet();
      updateStatsMap();
    }
  },[finalPrices, forecastLabels]);

  function updateStatsMap() {
    for (let i = 1; i < forecastLabels.length; i++) {
      if(!statsMap[forecastLabels[i]]) {
        const stat = {};
        stat.PriceOpen = Math.round(finalPrices[i - values.length]);
        stat.PriceClose = Math.round(finalPrices[i - values.length + 1]);
        stat.Forecasted = true;
        forecastStatsMap[forecastLabels[i]] = stat;
      }
    }
    setForecastStatsMap(forecastStatsMap)
  }

  function updateDataSet() {
    let datasets = []

    let dataset1 = {
      label: ticker + " Actual",
      borderWidth: 2,
      fill: true,
      lineTension: 0.5,
      point: {
        radius: 0,  // Set radius to 0 to hide points
      },
      pointHoverRadius: 0,
      data: values,
      color: "info",
      borderColor: "#d048b6",
      backgroundColor: (context) => {
        const ctx = context.chart.ctx;
        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        gradientStroke.addColorStop(1, "rgba(180,100,228,0.25)");
        gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.1)");
        gradientStroke.addColorStop(0, "rgba(119,52,169,0.05)"); //purple colors
        return gradientStroke;
      },
    }
    datasets.push(dataset1);

    let dataset2 = {
      label: ticker + " Forecast",
      borderWidth: 1,
      fill: true,
      lineTension: 0.5,
      point: {
        radius: 0, // Set radius to 0 to hide points
      },
      pointHoverRadius: 0,
      borderDash: [5, 5], // Set borderDash for a dotted line
      data: [...Array(labels.length - 1).fill(null), ...finalPrices],
      pointBorderWidth: 2,
      pointHoverBorderWidth: 1,
      pointRadius: 2,
      borderColor: "rgb(180,100,220)",
      backgroundColor: (context) => {
        const ctx = context.chart.ctx;
        let gradientStroke = ctx.createLinearGradient(0, 500, 0, 230);
        gradientStroke.addColorStop(1, "rgba(180,100,228,0.25)");
        gradientStroke.addColorStop(0.4, "rgba(100,100,228,0.02)");
        gradientStroke.addColorStop(0, "rgba(10,190,228,0.01)"); //blue colors
        return gradientStroke;
      },
    };
    datasets.push(dataset2);

    setDatasets(datasets);
  }

  let x = {
    labels: forecastLabels,
    datasets: datasets
  };


  return (
      <>
        {datasets.length > 0 ?
            <div className="chart-area multi-line-chart">
              <div style={{ display: 'inline-block', marginBottom: '20px'}}>
                <label htmlFor="startYear">Select volatility index</label>
                <select id="startYear" className="dropdown" value={volatilityIndex} onChange={handleChange}>
                  <option value="">Select...</option>
                  {[1, 3, 5, 7, 10, 15].map((index) => (
                      <option key={index} value={index}>
                        {index}
                      </option>
                  ))}
                </select>
                <label htmlFor="startYear"> diminishing returns index</label>
                <select id="startYear" className="dropdown" value={diminishingReturnsOption} onChange={handleChangeDiminishingReturnsFactor}>
                  <option value="">Select...</option>
                  {[1, 2, 3, 4].map((index) => (
                      <option key={index} value={index}>
                        {index}
                      </option>
                  ))}
                </select>
                <label htmlFor="startYear"> number of point to generate</label>
                <select id="startYear" className="dropdown" value={numberOfPointsOption} onChange={handleChangeNumberOfPoints}>
                  <option value="">Select...</option>
                  {[96, 150, 200, 300].map((index) => (
                      <option key={index} value={index}>
                        {index}
                      </option>
                  ))}
                </select>
              </div>
              <Line
                  data={x}
                  // options={chartOptions}
                  width={window.innerWidth < 800 ? window.innerWidth - 330 : window.innerWidth - 360}
                  height={400}
              />
              <CryptoMatrix project={name} ticker={ticker} statsMap={forecastStatsMap}/>
            </div> : null
        }
      </>
  );
}

export default ForecastLineChart;
