/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar, ScriptableContext } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";
import LanguageLabels from "../labels/LanguagesLabels";
import PricesTable from "./PricesTable";

require("./MultiLineChartV2.css");
const MultiLineChartSP500= ({name, ticker, currentPrice}) => {
  const [datasets, setDatasets] = React.useState([]);
  const [labels, setLabels] = useState([]);
  const [prices, setPrices] = useState([]);
  const existingPrices = [
    16.88, 21.21, 24.19, 26.18, 25.46, 35.60, 44.15, 45.43, 41.12, 55.62,
    58.03, 59.72, 69.07, 65.06, 76.45, 86.12, 93.32, 84.45, 95.04, 102.00,
    90.31, 93.49, 103.30, 118.40, 96.11, 72.56, 96.86, 103.80, 90.25, 99.71,
    110.90, 133.00, 117.30, 144.30, 166.40, 171.60, 208.20, 264.50, 250.50,
    285.40, 339.97, 325.49, 416.08, 435.23, 472.99, 465.25, 614.42, 766.22,
    963.36, 1248.77, 1425.59, 1335.63, 1140.21, 895.84, 1132.52, 1181.41,
    1278.73, 1424.16, 1378.76, 865.58, 1123.58, 1282.62, 1300.58, 1480.40,
    1822.36, 2028.18, 1918.60, 2275.12, 2789.80, 2607.39, 3278.20, 3793.75,
    4573.82, 3960.66, 4544.87
  ];

  const [comparePrices, setComparePrices] = useState([]);

  const startYear = 1949;
  const endYear = 2024;

  const availableOptions = [1950, 1960, 1970, 1980, 1990, 2000, 2010, 2024];
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [selectedEndOption, setSelectedEndOption] = useState(undefined);
  const [startFrom, setStartFrom] = useState(0);
  const [selectedEndYear, setSelectedEndYear] = useState(endYear);


  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleEndChange = (event) => {
    setSelectedEndOption(event.target.value);
  };

  useEffect(() => {
    setComparePrices(existingPrices);
  },[]);

  useEffect(() => {
    if (selectedOption) {
      const year = parseInt(selectedOption);
      const startFrom = year - startYear;
      setStartFrom(startFrom);
      if (selectedEndYear) {
        setComparePrices(existingPrices.slice(startFrom, selectedEndYear - startYear))
      } else {
        setComparePrices(existingPrices.slice(startFrom))
      }
    }
  },[selectedOption]);

  useEffect(() => {
    if(selectedEndOption){
      const endY = parseInt(selectedEndOption);
      setSelectedEndYear(endY)
      const end = endY - startYear;
      setComparePrices(existingPrices.slice(startFrom, end))
    }
  }, [selectedEndOption]);

  useEffect(() => {
    const startYear = 2024;
    const endYear = 2024 + comparePrices.length - 1;
    const labels = [];
    for (let year = startYear; year <= endYear; year++) {
      labels.push(`${year}`);
    }
    setLabels(labels);

    const prices = [];
    prices.push(currentPrice);
    comparePrices.forEach((price, index) => {
      if (index > 0) {
        const priceChange = (price - comparePrices[index - 1]) / comparePrices[index - 1];
        currentPrice *= (1 + priceChange);
        prices.push(currentPrice);
      }
    });

    setPrices(prices);
  }, [comparePrices]);

  function normalize(data) {
    let normalizedData = [];

    let min = Math.min(...data);
    let max = Math.max(...data);

    for (let value of data) {
      let normalizedValue = (value - min) / (max - min);
      normalizedData.push(normalizedValue);
    }

    return normalizedData;
  }


  React.useEffect(() => {
    let datasets = []
    let dataset = {
      label: "S&P500",
      borderWidth: 1,
      fill: true,
      lineTension: 0.5,
      point: {
        radius: 0,  // Set radius to 0 to hide points
      },
      pointHoverRadius: 0,
      data: normalize(comparePrices).map(x=>x*0.85),
      color: "info",
      borderColor: "rgb(238,232,170)",
      backgroundColor: (context) => {
        const ctx = context.chart.ctx;
        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 350);
        gradientStroke.addColorStop(1, "rgba(0,100,0,0.25)");
        gradientStroke.addColorStop(0.4, "rgba(50,205,50,0.2)");
        gradientStroke.addColorStop(0, "rgba(154,205,50,0.05)");
        return gradientStroke;
      },
    }
    if (prices.length > 0) {
      let dataset2 = {
        label: `${name}`,
        borderWidth: 2,
        fill: true,
        lineTension: 0.5,
        point: {
          radius: 0,  // Set radius to 0 to hide points
        },
        pointHoverRadius: 0,
        data: normalize(prices),
        color: "info",
        borderColor: "rgb(218,165,32)",
        backgroundColor: "rgba(250,250,210, 0.2)",
      }
      datasets.push(dataset2);
      datasets.push(dataset);
    }
    setDatasets(datasets);
  }, [prices]);

  let x = {
    labels: labels,
    datasets: datasets
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            // Customize the tooltip label here
            const labelIndex = context.dataIndex;
            var value = prices[labelIndex];
            var suffix= "";
            if (context.dataset.label.includes("S&P500")) {
              value = comparePrices[labelIndex];
              suffix = labels[labelIndex]
              suffix = parseInt(suffix) - comparePrices.length;
            }
            const customLabelLeft = `${suffix} ${context.dataset.label}: ${value.toFixed(2)} USD`;

            return [customLabelLeft];
          },
        },
      },
    },
    animation: {
      duration: 2000, // Set the animation duration in milliseconds
    },
    scales: {
      x: {
        axis: 'time',
        time: {
          unit: 'month',
        },
      },
      x1: {
        position: 'top',
        ticks: {
          callback: (value, index) => {
            return  selectedEndYear - (labels.length - index);
          },
        },
      },
      left: {
        axis: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'S&P500 Price USD',
        },
        ticks: {
          callback: (value, index) => {
            var min = Math.min(...comparePrices);
            var max = Math.max(...comparePrices);
            if (index === 0) {
              return min.toFixed(2);
            } else if (index === 10) {
              return (max * 1.01).toFixed(2);
            } else {
              const ratio = index / 10;
              return (min + ratio * (max - min)).toFixed(2);
            }
          },
        },
      },
      right: {
        axis: 'linear',
        position: 'right',
        title: {
          display: true,
          text: `${name} USD Price`,
        },
        ticks: {
          callback: (value, index) => {
            var min = Math.min(...prices);
            var max = Math.max(...prices);
            if (index === 0) {
              return min.toFixed(2);
            } else if (index === 10) {
              return (max * 1.01).toFixed(2);
            } else {
              const ratio = index / 10;
              return (min + ratio * (max - min)).toFixed(2);
            }
          },
        },
      },
    },
  };

  return (
      <>
        {datasets.length > 0 ?
            <div className="chart-area multi-line-chart">
              <Card className="card-chart">
                <div className={"crypto-desc"}>
                  <p><img style={{"width": "20px"}} className="project-logo"
                          alt={ticker}
                          src={"https://cryptometheus.com/".concat("api/logos/").concat(ticker).concat(".png")}/>Explore {name}'s ({ticker}) potential future price in 2030, 2040, 2050 and even beyond.
                  </p>
                  <p><strong>Explore a potential scenario: </strong>
                    Using the percentage changes in <strong>S&P500</strong> prices since 1949, envision how <strong>{name} ({ticker})</strong> prices could follow a similar trajectory.
                  </p>
                  <p>
                    Dive into the future of crypto with our predictive tools.
                  </p>
                </div>
              </Card>
              <div style={{ display: 'inline-block', marginBottom: '20px'}}>
                <label htmlFor="startYear">Select the time series of <strong>S&P500</strong> prices to be considered for projecting the future values of <strong>{name} ({ticker})</strong>, start year :</label>
                <select id="startYear" className="dropdown" value={selectedOption} onChange={handleChange}>
                  <option value="">{selectedOption ? "Select..." : startYear}</option>
                  {availableOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                  ))}
                </select>
                <p style={{ display: 'inline-block', margin: '0' }}> end year:</p>
                <select id="endYear" className="dropdown" value={selectedEndOption} onChange={handleEndChange}>
                  <option value="">{selectedEndOption ? "Select..." : endYear}</option>
                  {availableOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                  ))}
                </select>
              </div>
              <Line
                  data={x}
                  options={chartOptions}
                  width={window.innerWidth < 800 ? window.innerWidth - 330 : window.innerWidth - 360}
                  height={400}
              />
              <PricesTable name={"S&P500"}
                           labels={labels.map((label, index) => selectedEndYear + index)}
                           btcLabels={labels}
                           prices={comparePrices}
                           btcPrices={prices}/>
            </div> : null
        }
      </>
  );
}

export default MultiLineChartSP500;
