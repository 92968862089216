import React from 'react'
import { Label } from 'semantic-ui-react'

import haskell from "assets/languages/haskell.png";
import js from "assets/languages/js.png";
import cpp from "assets/languages/cpp.png";
import python from "assets/languages/python.png";
import html from "assets/languages/html.png";
import c from "assets/languages/c.png";
import dockerfile from "assets/languages/dockerfile.png";
import go from "assets/languages/go.png";
import nix from "assets/languages/nix.png";
import scala from "assets/languages/scala.png";
import shell from "assets/languages/shell.png";
import solidity from "assets/languages/solidity.png";
import typescript from "assets/languages/typescript.png";
import rust from "assets/languages/rust.png";
import ProjectDetails from "../ProjectDetails";
import {Row, Tooltip, UncontrolledTooltip} from "reactstrap";


require("./LanguageLabels.css")

const LanguageLabels = ({languages, parent}) => {

    const [elements, setElements] = React.useState([]);

    React.useEffect(() => {
        let ar = [];
        for (let i = 0; i < 3; i++) {
            if (languages[i] && getImg(languages[i])) {
                ar.push({'name': languages[i], "index": i});
            }
        }
        setElements(ar);
    }, [languages]);

    function getImg(language) {
        if (!language) {
            return html;
        }
        switch (language.toLowerCase()) {
            case "haskell":
                return haskell;
            case "javascript":
                return js;
            case "c++":
                return cpp;
            case "python":
                return python;
            case "go":
                return go;
            case "dockerfile":
                return dockerfile;
            case "c":
                return c;
            case "nix":
                return nix;
            case "scala":
                return scala;
            case "rust":
                return rust;
            case "shell":
                return shell;
            case "solidity":
                return solidity;
            case "typescript":
                return typescript;
            default:
                return undefined;
        }
    }

    return (
        <>
            <div id="languageContainer">
                {
                    elements != undefined ?
                        elements.map(({name, index}) => (
                            <Label id={"language-" + index + "-" + parent} data-title="Languages" key={name} as='a'
                                   className="label label-language">
                                <img className="image" src={getImg(name)} alt={name}/>
                            </Label>
                        )) : undefined
                }
                {
                    elements != undefined ?
                        elements.map(({name, index}) => (
                            <UncontrolledTooltip key={"#language-" + index + "-" + parent} target={"#language-" + index + "-" + parent}>
                                {name}
                            </UncontrolledTooltip>
                        )) : undefined
                }
            </div>

        </>
    )
}

export default LanguageLabels;