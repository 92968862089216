/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar, ScriptableContext } from "react-chartjs-2";
import {
  basicChart,
  chartOptions
} from "variables/my_charts.js";

// reactstrap components
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col, UncontrolledTooltip
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";

import LineChart from "views/charts/LineChart.js"
import BarChart from "views/charts/BarChart.js"
import {func} from "prop-types";
import PurpleBarChart from "./charts/PurpleBarChart";
import SimpleTable from "../components/tables/SimpleTable";
import ReposTable from "../components/tables/ReposTable";
import PurpleLineChart from "./charts/PurpleLineChart";
import LanguageLabels from "./labels/LanguagesLabels";
import {getPeriod} from "../utils/TimeframeUtil";


require("./ProjectDetails.css");

const ProjectDetails = ({changeTimespan, timespan, index, name, ticker, totalCommits, labelsD, dataD, labelsW, dataW, dataM, labelsM, repos, gitUrl,
                          numberOfRepository, numberOfContributors, languages, period}) => {
  const [chart, setChart] = React.useState(1);
  const [reposNames, setReposNames] = React.useState([]);
  const [reposValues, setReposValues] = React.useState([]);
  const [internalTimespan, setInternalTimespan] = React.useState(3);
  const [periodLabel, setPeriodLabel] = React.useState([]);

    React.useEffect(() => {
        setPeriodLabel(getPeriod(period, internalTimespan).longName);
    }, [internalTimespan, period]);

    React.useEffect(() => {
    let reposNames = [];
    let reposValues = [];
    for (let i = 0; i < repos.length; i++) {
      reposNames.push(repos[i].Name);
      reposValues.push(repos[i].TotalCommits);
    }
    setReposNames(reposNames);
    setReposValues(reposValues);
  }, [repos]);

    React.useEffect(() => {
        if(internalTimespan != timespan) {
            setInternalTimespan(timespan);
            switch (timespan) {
                case 3:
                    setChart(2);
                    break;
                case 2:
                case 1:
                    setChart(1);
            }
        }
    }, [timespan]);

  function changeInternalTimespan(internalTimespan) {
      switch (internalTimespan) {
          case 3:
              setChart(2);
              setInternalTimespan(3);
              break;
          case 2:
              setChart(1);
              if (dataW.length > 0)
                  setInternalTimespan(2);
              else {
                  setInternalTimespan(2);
                  changeTimespan(2);
              }
              break;
          case 1:
              setChart(1);
              if (dataD.length > 0)
              setInternalTimespan(1);
              else {
                  setInternalTimespan(1);
                  changeTimespan(1);
              }
      }
      // if (internalTimespan != timespan) {
      //     changeTimespan(internalTimespan);
      // }
  }

  return (
      <>
        <div id={"project-" + ticker} className="project-container">
          <Row className="timespan-buttons" >
            <div>
              <ButtonGroup
                  className="btn-group-toggle"
                  data-toggle="buttons"
              >
                <Button
                    tag="label"
                    className={classNames("btn-simple", {
                      active: internalTimespan === 1
                    })}
                    color="info"
                    // color="#00d6b4"
                    id="0"
                    size="sm"
                    onClick={() => changeInternalTimespan(1)}
                >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                           Daily
                        </span>
                  <span className="d-block d-sm-none">
                          <i className="tim-icons icon-double-left"/>
                        </span>
                </Button>
                <Button
                    color="info"
                    id="1"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: internalTimespan === 2
                    })}
                    onClick={() => changeInternalTimespan(2)}
                >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Weekly
                        </span>
                  <span className="d-block d-sm-none">
                          <i className="tim-icons icon-minimal-left"/>
                        </span>
                </Button>
                <Button
                    color="info"
                    id="1"
                    size="sm"
                    tag="label"
                    className={classNames("btn-simple", {
                      active: internalTimespan === 3
                    })}
                    onClick={() => changeInternalTimespan(3)}
                >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Monthly
                        </span>
                  <span className="d-block d-sm-none">
                          <i className="tim-icons icon-minimal-down"/>
                        </span>
                </Button>
              </ButtonGroup>
            </div>
          </Row>
          <Row id={"project-" + name.toLowerCase()}>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="6">
                      <h5 className="card-category">{index}. {ticker}</h5>
                      <Row style={{"width" : "200px"}}>
                        <Col>
                          <CardTitle>
                            <a href={"https://cryptometheus.com/project/"+ticker}><img style={{"width" : "30px"}} src={"https://cryptometheus.com/".concat("api/logos/").concat(ticker).concat(".png")}
                             alt={ticker}/> {name}</a>
                          </CardTitle>
                        </Col>
                      {/*<Col>*/}
                      {/*<CardTitle tag="h2">{name}</CardTitle>*/}
                      {/*</Col>*/}
                      </Row>
                      <h3 className="card-category">Core commits</h3>
                      <CardTitle id="total-commits" tag="h3">
                        <i className="tim-icons icon-pencil text-info"/> {totalCommits}
                      </CardTitle>
                        <UncontrolledTooltip target="#total-commits">
                            <p className={"tool-text"}>Generated commits or commits originating from forked repositories are not included</p>
                        </UncontrolledTooltip>
                    </Col>
                    <Col>
                      <ButtonGroup
                          className="btn-group-toggle float-right"
                          data-toggle="buttons"
                      >
                        <Button
                            tag="label"
                            className={classNames("btn-simple", {
                              active: chart === 1
                            })}
                            color="info"
                            id="0"
                            size="sm"
                            onClick={() => setChart(1)}
                        >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          <i className="tim-icons icon-chart-bar-32"/>
                        </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-chart-bar-32"/>
                        </span>
                        </Button>
                        <Button
                            color="info"
                            id="1"
                            size="sm"
                            tag="label"
                            className={classNames("btn-simple", {
                              active: chart === 2
                            })}
                            onClick={() => setChart(2)}
                        >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          <i className="tim-icons icon-sound-wave"/>
                        </span>
                          <span className="d-block d-sm-none">
                          <i className="tim-icons icon-sound-wave"/>
                        </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {
                    {
                      1: <div className="chart-area">
                        {
                          {
                            1: <BarChart name={name} labels={labelsD} data={dataD} totalCommits={totalCommits} fullscreen={true} periodLabel={periodLabel}/>,
                            2: <LineChart name={name} labels={labelsD} data={dataD} totalCommits={totalCommits} fullscreen={true} periodLabel={periodLabel}/>,
                          }[chart]
                        }
                      </div>,
                      2: <div className="chart-area">
                        {
                          {
                            1: <BarChart name={name} labels={labelsW} data={dataW} totalCommits={totalCommits} fullscreen={true} periodLabel={periodLabel}/>,
                            2: <LineChart name={name} labels={labelsW} data={dataW} totalCommits={totalCommits} fullscreen={true} periodLabel={periodLabel}/>,
                          }[chart]
                        }
                      </div>,
                      3: <div className="chart-area">
                        {
                          {
                            1: <BarChart name={name} labels={labelsM} data={dataM} totalCommits={totalCommits} fullscreen={true} periodLabel={periodLabel}/>,
                            2: <LineChart name={name} labels={labelsM} data={dataM} totalCommits={totalCommits} fullscreen={true} periodLabel={periodLabel}/>,
                          }[chart]
                        }
                      </div>
                    }[internalTimespan]
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {/*<Col>*/}
            {/*  {*/}
            {/*    {*/}
            {/*      2: <PurpleBarChart name={name} labels={labels} data={data} totalCommits={totalCommits}/>,*/}
            {/*      1: <PurpleBarChart name={name} labels={labelsM} data={dataM} totalCommits={totalCommits}/>,*/}
            {/*    }[timeframe]*/}
            {/*  }*/}
            {/*</Col>*/}
            <Col>
              {
                {
                  // 2: <PurpleBarChart name="Commit count"
                  //  index={ticker}
                  //                    labels={reposNames}
                  //                    data={reposValues}
                  //                    numberOfRepository={numberOfRepository}
                  //                    numberOfContributors = {numberOfContributors}
                  //                    languages = {languages}/>,
                    2: <PurpleLineChart name="Commit count"
                                        index={ticker}
                                        labels={reposNames}
                                        data={reposValues}
                                        numberOfRepository={numberOfRepository}
                                        numberOfContributors = {numberOfContributors}
                                        languages = {languages}/>,
                  1: <PurpleLineChart name="Commit count"
                                      index={ticker}
                                      labels={reposNames}
                                      data={reposValues}
                                      numberOfRepository={numberOfRepository}
                                      numberOfContributors = {numberOfContributors}
                                      languages = {languages}/>,
                }[chart]
              }
            </Col>
            <Col>
              <ReposTable elements={repos.slice(0,5)} gitUrl={gitUrl}/>
            </Col>
          </Row>
            <Row>
                <a className="link" href={`https://cryptometheus.com/crypto/${ticker}`} rel="canonical">Click to Explore Our {name} ({ticker}) Price Prediction Tool</a>
            </Row>
        </div>
      </>
  );
}

export default ProjectDetails;
