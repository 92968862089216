/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
  NavItem,
  UncontrolledTooltip
} from "reactstrap";
import { Hint } from 'react-autocomplete-hint';
import logo from "assets/img/logos/logo_v2.png";
import compare from "assets/img/balance.png";
import moon from "assets/img/icons/moon.png";
import sun from "assets/img/icons/sun.png";
import tokenomics from "assets/img/icons/tokenomics.webp";

import  TimeframeDropdown from "components/FixedPlugin/TimeframeDropdown";
import { ThemeContext, themes } from "contexts/ThemeContext";

require("./AdminNavbar.css");

function AdminNavbar(props) {
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-transparent");
  const [text, setText] = React.useState('')
  const [allProjects, setAllProjects] = React.useState([]);
  const [lightMode, setlLightMode] = React.useState(true);


  React.useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      headers.append('authority', "cryptometheus.com");
      const response = await fetch("https://cryptometheus.com/".concat("api/crypto/projects/names?apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf"),
          {headers: headers});
      // convert the data to json
      const json = await response.json();
      setAllProjects(json);
    }
    fetchData()
        .catch(console.error);
  }, []);

  function updateInputValue(evt) {
    //setSearchCity(evt.target.value);
    setText(evt.target.value);
  }

  function onKeyDownSearchbar(e) {
    if (e.key === 'Enter') {
      if (text) {
        triggerProjectSearch(text);
      }
    }
  }

  function triggerProjectSearch(project) {
    // filteredStrings = filterstrings.filter((str) => str.toLowerCase().includes(passedinstring.toLowerCase())
    let x = project;
    if (x.indexOf(" ")) {
      x = x.split(" ")[0].replace(/\s/g, '');
    }
    if (allProjects.some(item => item.toLowerCase().indexOf(x.toLowerCase()))) {
      const element = document.getElementById("project-" + x.toLowerCase());
      const y = element.getBoundingClientRect().top + window.pageYOffset - 80;
      const mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
      if (mac) {
        // document.getElementById("project-" + Ticker).scrollIntoView();
        window.scrollTo({top: y, behavior: 'smooth'});
      } else {
        console.log("---NativeSmoothScroll not supported---")
        // window.scrollTo({top: y});
        document.getElementById("project-" + x.toLowerCase()).scrollIntoView(false);
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };
  return (
      <>
        <Navbar className={classNames("navbar-top", "navbar-transparent")} expand="lg" sticky="top">
          <Container fluid>
            <div className="navbar-wrapper">
              <div
                  className={classNames("navbar-toggle d-inline", {
                    toggled: props.sidebarOpened
                  })}
              >
                <NavbarToggler onClick={props.toggleSidebar}>
                  <span className="navbar-toggler-bar bar1"/>
                  <span className="navbar-toggler-bar bar2"/>
                  <span className="navbar-toggler-bar bar3"/>
                </NavbarToggler>
              </div>
              <NavbarBrand href="/">
                {/*<img style={{"width" : "30px"}} src={logo}/>{props.brandText}*/}
                <div className="site-logo-img">
                  <img className="logo-img" src={logo} alt="site-logo"/>
                </div>
              </NavbarBrand>
            </div>
            <NavbarToggler className="navbar-top" onClick={toggleCollapse}>
              <span className="navbar-toggler-bar navbar-kebab"/>
              <span className="navbar-toggler-bar navbar-kebab"/>
              <span className="navbar-toggler-bar navbar-kebab"/>
            </NavbarToggler>
            <Collapse navbar isOpen={collapseOpen}>
              <Nav className="ml-auto" navbar>
                <ThemeContext.Consumer>
                  {({changeTheme}) => (
                      <NavItem
                          id="light-nav" onClick={() =>
                          {lightMode ? changeTheme(themes.dark) : changeTheme(themes.light);
                            lightMode ? setlLightMode(false) : setlLightMode(true)}
                      }>
                          <div className="photo">
                            <img className="light-img compare-img" src={lightMode ? moon : sun} alt="moon-logo"/>
                          </div>
                        <UncontrolledTooltip target="#light-nav">
                          {lightMode ? "Dark mode" : "Light mode"}
                        </UncontrolledTooltip>
                      </NavItem>
                  )}
                </ThemeContext.Consumer>
                <InputGroup className="search-bar">
                  <Button color="link" onClick={toggleModalSearch}>
                    <i className="tim-icons icon-zoom-split"/>
                    <span className="d-lg-none d-md-block">Search</span>
                  </Button>
                </InputGroup>
                <UncontrolledDropdown id="timespan-dropdown" nav>
                  <DropdownToggle
                      caret
                      color="default"
                      data-toggle="dropdown"
                      nav
                  >
                    <div className="notification d-none d-lg-block d-xl-block"/>
                    <i className="tim-icons icon-sound-wave"/>
                    <p className="d-lg-none">Timespan</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li">
                      <DropdownItem className="nav-item"
                                    onClick={() => props.handleTimespanChange(1)}>
                        Daily
                      </DropdownItem>
                      <DropdownItem className="nav-item"
                                    onClick={() => props.handleTimespanChange(2)}>
                        Weekly
                      </DropdownItem>
                      <DropdownItem className="nav-item"
                                    onClick={() => props.handleTimespanChange(3)}>
                        Monthly
                      </DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                  <UncontrolledTooltip target="#timespan-dropdown">
                    Adjust timespan
                  </UncontrolledTooltip>
                </UncontrolledDropdown>
                <TimeframeDropdown changeTimeframe={props.handleTimeframeChange}></TimeframeDropdown>
                <NavItem
                    id="compare-nav">
                  <NavLink
                      href="https://cryptometheus.com/compare"
                      target="_blank"
                      id="compare-tooltip"
                  >
                    <div className="photo">
                      <img className="compare-img" src={compare} alt="Side-by-side comparison of various cryptocurrencies"/>
                    </div>
                    <p className="d-lg-none">Compare</p>
                  </NavLink>
                  <UncontrolledTooltip target="#compare-tooltip">
                    Compare two projects
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem
                         id="tokenomics-nav">
                  <NavLink
                      href="/tokenomics"
                      target="_blank"
                      id="tokenomics-tooltip"
                  >
                    <div className="photo">
                      <img className="compare-img" src={tokenomics} alt="Token distribution fot Top Cryptocurrencies"/>
                    </div>
                    <div className="new-feature">
                      {"New"}
                    </div>
                    <p className="d-lg-none">Tokenomics</p>
                  </NavLink>
                  <UncontrolledTooltip target="#tokenomics-tooltip">
                    Tokenomics
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem
                    id="twitter-nav">
                  <NavLink
                      href="/articles"
                      target="_blank"
                      id="articles-tooltip"
                  >
                    <i className="tim-icons icon-notes"></i>
                    <p className="d-lg-none d-xl-none">Articles</p>
                  </NavLink>
                  <UncontrolledTooltip target="#articles-tooltip">
                    Articles
                  </UncontrolledTooltip>
                </NavItem>
                <NavItem
                    id="twitter-nav">
                  <NavLink
                      href="https://twitter.com/Cryptometheu"
                      target="_blank"
                      id="twitter-tooltip"
                  >
                    <i className="fab fa-twitter"></i>
                    <p className="d-lg-none d-xl-none">Twitter</p>
                  </NavLink>
                  <UncontrolledTooltip target="#twitter-tooltip">
                    Follow us on Twitter
                  </UncontrolledTooltip>
                </NavItem>
                {/*<UncontrolledDropdown nav>*/}
                {/*  <DropdownToggle*/}
                {/*    caret*/}
                {/*    color="default"*/}
                {/*    nav*/}
                {/*    onClick={(e) => e.preventDefault()}*/}
                {/*  >*/}
                {/*    <div className="photo">*/}
                {/*      <img alt="..." src={require("assets/img/anime3.png")} />*/}
                {/*    </div>*/}
                {/*    <b className="caret d-none d-lg-block d-xl-block" />*/}
                {/*    <p className="d-lg-none">Log out</p>*/}
                {/*  </DropdownToggle>*/}
                {/*  <DropdownMenu className="dropdown-navbar" right tag="ul">*/}
                {/*    <NavLink tag="li">*/}
                {/*      <DropdownItem className="nav-item">Profile</DropdownItem>*/}
                {/*    </NavLink>*/}
                {/*    <NavLink tag="li">*/}
                {/*      <DropdownItem className="nav-item">Settings</DropdownItem>*/}
                {/*    </NavLink>*/}
                {/*    <DropdownItem divider tag="li" />*/}
                {/*    <NavLink tag="li">*/}
                {/*      <DropdownItem className="nav-item">Log out</DropdownItem>*/}
                {/*    </NavLink>*/}
                {/*  </DropdownMenu>*/}
                {/*</UncontrolledDropdown>*/}
                <li className="separator d-lg-none"/>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <Modal
            modalClassName="modal-search"
            isOpen={modalSearch}
            toggle={toggleModalSearch}
        >
          <ModalHeader>
            {/*<Input placeholder="SEARCH" type="text" />*/}
            <Hint options={allProjects} allowTabFill>
              <input
                  className='input-with-hint'
                  value={text}
                  placeholder={"Search for project"}
                  onChange={e => updateInputValue(e)}
                  onKeyDown={e => onKeyDownSearchbar(e)}
              />
            </Hint>
            <button
                aria-label="Close"
                className="close"
                onClick={toggleModalSearch}
            >
              <i className="tim-icons icon-simple-remove"/>
            </button>
          </ModalHeader>
        </Modal>
      </>
  );
}

export default AdminNavbar;
