import React from 'react'

require("./Thumbnail.css")


const Thumbnail = ({image, text1, x1, y1, text2, x2, y2, large, onClick}) => {
    return (
        <>
            <div id="shape-container"
               // stroke="none"
               // strokeWidth="1"
               // fill="white"
               // fillRule="evenodd"
               // transform="translate(-41, -42)"
               visibility="visible"
            >
                <svg width={large?"330":"300"} height={large?"220":"200"}
                     xmlns="http://www.w3.org/2000/svg"
                >
                    <image
                        className={large ? " img-thumbnail shadow" : "img-thumbnail"}
                        onClick={() => onClick()}
                        onMouseOver={() => {
                        }
                        }
                        onMouseOut={() => {
                        }
                        }
                        id="city-image"
                        alt="article-1"
                        href={image}
                        width={large?"330":"300"}
                        height={large?"220":"200"}
                        clipPath="inset(16px round 16px)"
                    />
                    {/*<image href={imgSrc} width="225" height="150" opacity="0.98" className="rounded img-raised"/>*/}
                    {/*{loaded === true ?*/}
                    <text
                        id="city-name" className="preview"
                        x={x1}
                        y={y1}
                        fill="white" fontWeight="bold"
                        onClick={() => onClick()}
                    > {text1} </text>
                    <text
                        id="city-name" className="preview"
                        x={x2}
                        y={y2}
                        fill="white" fontWeight="bold"
                        onClick={() => onClick()}
                    >{text2}</text>
                </svg>
            </div>
        </>
    )
}

export default Thumbnail;

