import React, { useEffect, useState } from 'react';
import './PriceMatrix.css';

const CryptoMatrix = ({project, ticker, statsMap }) => {
    const [highlightColor, setHighlightColor] = useState('green');
    const [defaultColor, setDefaultColor] = useState('red');

    const calculateColor = (priceChange) => {
        // Normalize priceChange to be between 0 and 1
        const normalizedValue = (priceChange + 100) / 200;

        // Interpolate between red and green based on the normalized value
        const red = Math.floor(255 * (1 - normalizedValue));
        const green = Math.floor(255 * normalizedValue);

        return `rgb(${red}, ${green}, 0)`;
    };

    const calculateSingleColor = (priceChange, forecasted) => {
        // Normalize priceChange to be between -1 and 1
        const normalizedValue = priceChange / 100;

        if (normalizedValue < 0) {
            const intensity = Math.abs(normalizedValue);
            const red = Math.floor(255 - 200 * intensity); // Adjust 200 for lighter/darker red
            if(!forecasted) {
                return `linear-gradient(to bottom, lightcoral, rgba(${red}, 0, 0, 0.85))`;
            }
            return `linear-gradient(to bottom, lightpink, rgba(${red}, 200, 200, 0.4))`;
        } else {
            // Green for non-negative values
            const intensity = normalizedValue;
            const green = Math.floor(200 - 200 * intensity); // Adjust 200 for lighter/darker green
            if(!forecasted) {
                return `linear-gradient(to bottom, lightgreen, rgba(0, ${green}, 0, 0.85)`;
            }
            return `linear-gradient(to bottom, lightgreen, rgba(50, ${green}, 50, 0.2)`;
        }
    };

    const renderMatrix = () => {
        if (!statsMap) {
            return null;
        }
        const keys = Object.keys(statsMap).filter((key) => !key.includes("2019"));

        let columns;
        if (window.innerWidth > 1500) {
            columns = 12;
        } else if (window.innerWidth > 1200) {
            columns = 9;
        } else if (window.innerWidth > 800) {
            columns = 6;
        } else {
            columns = 3;
        }
        const rows = Math.ceil(keys.length / columns);
        const matrix = [];

        for (let i = 0; i < rows; i++) {
            const row = [];
            for (let j = 0; j < columns; j++) {
                const index = i * columns + j;
                if (index >= keys.length) break;

                const month = keys[index];
                const stats = statsMap[month];

                // const bgColor = stats.PriceChange > 0
                //     ? `linear-gradient(to bottom, lightgreen, green)`
                //     : `linear-gradient(to bottom, lightcoral, red)`;
                var priceChange = Math.round(((stats.PriceClose - stats.PriceOpen) / stats.PriceOpen) * 100, 2);
                const bgColor = calculateSingleColor(priceChange, stats.Forecasted);


                row.push(
                    <div
                        key={month}
                        className={stats.Forecasted ? "matrix-item border" : "matrix-item"}
                        style={{
                            background: bgColor,
                        }}
                    >
                        <p>{month}</p>
                        <p><strong>{stats.PriceClose} USD</strong></p>
                        <h4>{priceChange} %</h4>

                        {stats.Forecasted ?
                            <div
                                className={priceChange > 0 ? "tooltip-card green-tooltip-light" : "tooltip-card red-tooltip-light"}>
                                <p><strong>Forecasted</strong> Price open: <strong>{stats.PriceOpen} USD</strong></p>
                                <p><strong>Forecasted</strong> Price close: <strong>{stats.PriceClose} USD</strong></p>
                                <p><strong>Forecasted</strong> Price change: <strong> {priceChange} %</strong></p>
                            </div>:
                            <div
                                className={priceChange > 0 ? "tooltip-card green-tooltip-light" : "tooltip-card red-tooltip-light"}>
                                <p>Price open: <strong>{stats.PriceOpen} USD</strong></p>
                                <p>Price close: <strong>{stats.PriceClose} USD</strong></p>
                                <p>Price low: <strong>{stats.PriceLow} USD</strong></p>
                                <p>Price high: <strong>{stats.PriceHigh} USD</strong></p>
                                <p>Price change: <strong> {priceChange} %</strong></p>
                                <p>Volume: <strong>{stats.Volume}</strong></p>
                                <p>Trades: <strong>{stats.Trades}</strong></p>
                            </div>
                        }
                    </div>
                );
            }
            matrix.push(<div key={i} style={{display: 'flex'}}>{row}</div>);
        }

        return matrix;
    };

    return (
        <div className="matrix">
            <h4>Monthly Price Movement Matrix for {project} ({ticker})</h4>
            {renderMatrix()}
        </div>
    );
};

export default CryptoMatrix;
