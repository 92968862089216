/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar, ScriptableContext } from "react-chartjs-2";
import {
  barChartOptions
} from "variables/my_charts.js";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";
import LanguageLabels from "../labels/LanguagesLabels";
import {chartOptions} from "../../variables/my_charts";


const PurpleBarChart = ({name, index, labels, data, numberOfRepository, numberOfContributors, languages, totalCommits, fullscreen}) => {

  let x = {
    labels: labels,
    datasets: [
      {
        label: name,
        fill: true,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

          gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
          gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
          gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors
          return gradientStroke;

        },
        hoverBackgroundColor: (context) => {
          const ctx = context.chart.ctx;
          let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

          gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
          gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
          gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors
          return gradientStroke;
        },
        borderColor: "#d048b6",
        // borderColor: "#1f8ef1", blue
        // borderColor: "#00d6b4", green
        // borderColor: "#1f8ef1",
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        data: data
      }
    ]
  };

  return (
      <>
        <div className="chart-area bar-chart">
          <Row>
            <Col>
              Number of contributors {numberOfContributors}
            </Col>
            <Col>
              Number of repositories {numberOfRepository}
            </Col>
            <Col>
              <LanguageLabels languages={languages} parent={index} className="float-right"/>
            </Col>
          </Row>
          {fullscreen ?
              <Bar
                  data={x}
                  options={barChartOptions}
                  width={window.innerWidth < 800 ? window.innerWidth - 30 : window.innerWidth-350}
                  height={300}
              /> :
              <Bar
                  data={x}
                  options={barChartOptions}
              />
          }
        </div>
      </>
  );
}

export default PurpleBarChart;
