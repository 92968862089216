/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";

import CommitsContainer from "views/CommitsContainer.js"

// core components
import {
  basicChart,
  chartOptions
} from "variables/my_charts.js";
import logo from "../assets/img/react-logo.png";
import Thumbnail from "../views/articles/Thumbnail";
import crypto from "../assets/img/articles/crypto.png";
import developer from "../assets/img/articles/developer.png";
import github from "../assets/img/articles/github.png";
import pop from "../assets/img/articles/pop.png";
import {useLocation} from "react-router-dom";

require("./Dashboard.css");

function Dashboard(props) {
  const location = useLocation();

  return (
      <>
        <div className="content">
          <Row id="commitsContainer">
            <CommitsContainer renderAll={props.renderAll} period={props.timeframe} timespan={props.timespan}
                              changeTimespan={props.changeTimespan} changeRenderAll={props.changeRenderAll}/>
            {location.pathname.indexOf("list") > 0 ?
                <Row className="thumbnails">
                  <Col><h4 className="articles">Articles:</h4></Col>
                  <Col>
                    <Thumbnail image={crypto} large={false} text1="What to Consider When" x1={70} y1={80}
                               text2="Investing in Cryptocurrencies?" x2={40} y2={110}
                               onClick={() => window.open("/articles/what_to_consider_when_investing_in_cryptocurrencies")}/>
                  </Col>
                  <Col>
                    <Thumbnail image={developer} large={false} text1="Why Developer Community" x1={50} y1={80}
                               text2=" is important for a Crypto Project?" x2={35} y2={110}
                               onClick={() => window.open("/articles/the_importance_of_developer_community_for_a_crypto_project")}/>
                  </Col>
                  <Col>
                    <Thumbnail image={github} large={false} text1="What is" x1={130} y1={80}
                               text2="GitHub?" x2={130} y2={105}
                               onClick={() => window.open("/articles/the_importance_of_github_commits_in_cryptocurrency_analysis")}/>
                  </Col>
                  <Col>
                    <Thumbnail large={false} image={pop} text1="How to assess the popularity" x1={50} y1={80}
                               text2="of a cryptocurrency?" x2={80} y2={110}
                               onClick={() => window.open("/articles/measuring_the_popularity_of_cryptocurrencies")}/>
                  </Col>
                </Row> : null
            }
          </Row>
        </div>
      </>
  );
}

export default Dashboard;
