/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import {Helmet} from "react-helmet";

// react plugin used to create charts
import { Line, Bar, ScriptableContext } from "react-chartjs-2";
import {
    basicChart,
    chartOptions
} from "variables/my_charts.js";


import ProjectDetailsV2 from "views/ProjectDetailsV2";
import {getPeriod} from "../../utils/TimeframeUtil";

const CompareProject= ({element, timeframe, timespan}) => {
    const [periodLabel, setPeriodLabel] = React.useState([]);

    React.useEffect(() => {
        setPeriodLabel(getPeriod(timeframe, timespan).longName);
    }, [timeframe, timespan]);


    return (
        <>
            <div id="single-project">
                {element ?
                    <ProjectDetailsV2
                        timespan={timespan}
                        key={element.Ticker}
                        index={element.Index}
                        name={element.Name}
                        ticker={element.Ticker}
                        totalCommits={element.ProjectTotalCommits}
                        labelsD={element.Commits.DailyFrame.Labels}
                        dataD={element.Commits.DailyFrame.Commits}
                        labelsW={element.Commits.WeeklyFrame.Labels}
                        dataW={element.Commits.WeeklyFrame.Commits}
                        labelsM={element.Commits.MonthlyFrame.Labels}
                        dataM={element.Commits.MonthlyFrame.Commits}
                        repos={element.Repositories}
                        gitUrl={element.GitUrl}
                        numberOfRepository={element.NumberOfRepository}
                        numberOfContributors={element.NumberOfContributors}
                        languages={element.Languages}
                        fullscreen={false}
                        displayTimespanButtons={false}
                        periodLabel={periodLabel}
                    /> : null
                }
            </div>
        </>
    );
}

export default CompareProject;
