/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import {Helmet} from "react-helmet";


// reactstrap components
import {
    Row,
    Col, Card
} from "reactstrap";

import CompareProject from "views/compare/CompareProject";
import MultiLineChart from "views/charts/MultiLineChart"
import {useLocation} from "react-router-dom";
import InfoView from "../InfoView";
import {getPeriod} from "../../utils/TimeframeUtil";
import Autocomplete from "../../components/dropdown/Autocomplete";

require("./CompareProjects.css");

const CompareProjects= ({timeframe, timespan}) => {
    const location = useLocation();
    const [projectA, setProjectA] = React.useState(undefined);
    const [projectB, setProjectB] = React.useState(undefined);
    const [elements, setElements] = React.useState([]);
    const [periodLabel, setPeriodLabel] = React.useState([]);
    const [allProjects, setAllProjects] = React.useState([]);

    React.useEffect(() => {
        setPeriodLabel(getPeriod(timeframe, timespan).longName);
    }, [timeframe, timespan]);

    React.useEffect(() => {
        const fetchData = async () => {
            const headers = new Headers();
            headers.append('authority', "cryptometheus.com");
            const response = await fetch("https://cryptometheus.com/".concat("api/crypto/projects/names?apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf"),
                {headers: headers});
            // convert the data to json
            const json = await response.json();
            setAllProjects(json);
        }
        fetchData()
            .catch(console.error);
    }, []);

    React.useEffect(() => {
        const pathParams = location.pathname.split("/");
        const projects = pathParams.length > 1 ? pathParams[2] : undefined;
        if (projects) {
            const projectsParams = projects.split("-vs-");
            const projectA = projectsParams[0];
            let projectB = undefined;
            if (projectsParams.length > 1) {
                projectB = projectsParams[1];
            }
            if (projectA) {
                setActiveProject(projectA, setProjectA)
            }
            if (projectB) {
                setActiveProject(projectB, setProjectB)
            }
        }
    }, [location]);

    function setActiveProject(projectName, setCallback) {
        const headers = new Headers();
        headers.append('authority', "cryptometheus.com");
        fetch("https://cryptometheus.com/".concat("api/crypto/project?key=" + encodeURIComponent(projectName) + "&apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf&v=1234" +
                "&timeframe=" + timeframe),
            {headers: headers})
            .then(response => response.json())
            .then(data => setCallback(data))
    }

    function getBaseProject(Ticker) {
        const headers = new Headers();
        headers.append('authority', 'cryptometheus.com');

        // Use template literals for better readability
        const url = `https://cryptometheus.com/api/crypto/base/project?key=${encodeURIComponent(Ticker)}&apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf&v=1234`;

        // Return the fetch promise to be handled in the calling function
        return fetch(url, { headers })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    React.useEffect(() => {
        // Use Promise.all to wait for both fetch operations to complete
        Promise.all([
            projectA && projectA.Ticker ? getBaseProject(projectA.Ticker) : Promise.resolve(null),
            projectB && projectB.Ticker ? getBaseProject(projectB.Ticker) : Promise.resolve(null),
        ])
            .then(([data1, data2]) => {
                // Handle the data and setElements
                let newElements = [];

                if (data1) {
                    // Set the Index property for data1
                    data1.Index = 0;
                    newElements.push(data1);
                }

                if (data2) {
                    // Set the Index property for data2
                    data2.Index = 1;
                    newElements.push(data2);
                }

                // Update state with newElements
                setElements(newElements);
            });
    }, [projectA, projectB]);


    const getTicker = project => {
        return project.split("(")[1].split(")")[0];
    }

    return (
        <>
            {
                projectA && projectB ? <Helmet>
                        <title>{"Developer report | " + projectA.Name + "  (" + projectA.Ticker + ") vs " + projectB.Name + " (" + projectB.Ticker + ") GitHub commit activity"}</title>
                        <meta name="description"
                              content={"Collecting metrics to compare " + projectA.Name + " (" + projectA.Ticker + ") vs " + projectB.Name + " (" + projectB.Ticker + ") GitHub commits"}/>
                        <meta name="keywords"
                              content={projectA.Ticker + ", " + projectA.Name + ", " + projectB.Ticker + ", " + projectB.Name + ", " + "GitHub, developer report, commits, developer activity, developers" + ", " + projectA.Name + " vs " + projectB.Name + ", " + projectA.Ticker + " vs " + projectB.Ticker}/>
                        {/*<link rel="canonical" href={"https://cryptometheus.com/compare/" + projectA.Ticker.toUpperCase() + "-vs-" + projectB.Ticker.toUpperCase()}/>*/}
                     </Helmet> :
                    <Helmet>
                        <title>{"Developer report | Compare top cryptocurrencies by development activity"}</title>
                        <meta name="description"
                              content={"Collecting metrics from GitHub to see how development activity of two different coins compare over time through a set of detailed graphs"}/>
                        <meta name="keywords"
                              content={"GitHub, compare commits, compare cryptocurrencies, compare development, developer report, commits, developer activity"}/>
                        {/*<link rel="canonical" href={"https://cryptometheus.com/compare"}/>*/}
                    </Helmet>
            }
            {
                !projectA && !projectB ?
                    <h1 className="compare-subtitle title">
                        Our cryptocurrency comparison tool offers a side-by-side comparison of various metrics.
                        Please select two coins and see how their GitHub development activity compare over time through
                        a set of detailed graphs.
                    </h1> : null}
            <Row>
                {(projectA && projectA.Insight) || (projectB && projectB.Insight) ?
                    <div className="project-description-compare">
                        <Card className="card-chart">
                            {projectA && projectA.Insight ?
                                <p className={"project-desc"}><img style={{"width": "20px"}} className="project-logo"
                                                                   alt={projectA.Ticker}
                                                                   src={"https://cryptometheus.com/".concat("api/logos/").concat(projectA.Ticker).concat(".png")}/>
                                    {projectA.Name} demonstrated {projectA.ProjectTotalCommits > 100 ? "strong" : "modest"} developer
                                    activity on
                                    GitHub with <b>{projectA.ProjectTotalCommits}</b> GitHub commits
                                    across <b>{projectA.NumberOfRepository}</b> core
                                    repositories during the selected timeframe.
                                    This accomplishment awarded the project a ranking of <u><b><font
                                        color={"blue"}>{projectA.Index}{projectA.Index % 10 === 1 ? "st" : (projectA.Index % 10 === 2 ? "nd" : (projectA.Index % 10 === 3 ? "rd" : "th"))}</font></b></u> place.
                                    Furthermore, over the previous 7 days,
                                    there {projectA.Insight.WeeklyCoreDevs == 1 ? "was " : "were "}
                                    <b>{projectA.Insight.WeeklyCoreDevs}</b> core developers who
                                    made <b>{projectA.Insight.WeeklyCoreCommits}</b> commits
                                    across <b>{projectA.Insight.CoreRepos}</b> core repositories in GitHub.
                                    The number of commits is on
                                    the {projectA.Insight.WeeklyCoreCommits1MChange < 0 ? "downtrend" : "uptrend"} with
                                    a monthly
                                    change of <font
                                        color={projectA.Insight.WeeklyCoreCommits1MChange > 0 ? "GREEN" : "RED"}><b>{projectA.Insight.WeeklyCoreCommits1MChange}%</b></font>.
                                </p> : null}
                            {projectB && projectB.Insight ?
                                <p className={"project-desc"}><img style={{"width": "20px"}} className="project-logo"
                                                                   alt={projectB.Ticker}
                                                                   src={"https://cryptometheus.com/".concat("api/logos/").concat(projectB.Ticker).concat(".png")}/>
                                    {projectB.Name} demonstrated {projectB.ProjectTotalCommits > 100 ? "strong" : "modest"} developer
                                    activity on
                                    GitHub with <b>{projectB.ProjectTotalCommits}</b> GitHub commits
                                    across <b>{projectB.NumberOfRepository}</b> core
                                    repositories during the selected timeframe.
                                    This accomplishment awarded the project a ranking of
                                    <u><b><font
                                        color={"blue"}>{projectB.Index}{projectB.Index % 10 === 1 ? "st" : (projectB.Index % 10 === 2 ? "nd" : (projectB.Index % 10 === 3 ? "rd" : "th"))}</font></b></u> place.
                                    Furthermore, over the previous 7 days,
                                    there {projectB.Insight.WeeklyCoreDevs == 1 ? "was " : "were "}
                                    <b>{projectB.Insight.WeeklyCoreDevs}</b> core developers who
                                    made <b>{projectB.Insight.WeeklyCoreCommits}</b> commits
                                    across <b>{projectB.Insight.CoreRepos}</b> core repositories in GitHub.
                                    The number of commits is on
                                    the {projectB.Insight.WeeklyCoreCommits1MChange < 0 ? "downtrend" : "uptrend"} with
                                    a monthly
                                    change of <font
                                        color={projectB.Insight.WeeklyCoreCommits1MChange > 0 ? "GREEN" : "RED"}><b>{projectB.Insight.WeeklyCoreCommits1MChange}%</b></font>.
                                </p> : null}
                        </Card></div> : null}
            </Row>
            <Row className="multi-chart">
                <MultiLineChart elements={elements} periodLabel={periodLabel}/>
            </Row>
            <div className="compare-container">
                <Row>
                    <Col className="compare-control">
                        <Autocomplete
                            className="autocomplete-input-controls"
                            suggestions={allProjects}
                            callback={(x) => {
                                setActiveProject(getTicker(x), setProjectA)
                            }}
                            placeholder={projectA ? "Change project " + projectA.Name : "Choose a project"}
                            activateOnInputClick={true}
                        />
                    </Col>
                    <Col className="compare-control">
                        <Autocomplete
                            className="autocomplete-input-controls"
                            suggestions={allProjects}
                            callback={(x) => {
                                setActiveProject(getTicker(x), setProjectB)
                            }}
                            placeholder={projectB ? "Change project " + projectB.Name : "Choose a project"}
                            activateOnInputClick={true}
                        />
                    </Col>
                </Row>
                <Row>
                    {projectA ?
                        <Col md={6}>
                            <CompareProject element={projectA} timespan={timespan} timeframe={timeframe}/>
                        </Col> : null}
                    {projectB ?
                        <Col md={6}>
                            <CompareProject element={projectB} timespan={timespan} timeframe={timeframe}/>
                        </Col> : null}
                </Row>
            </div>
            <div className="post">
                <InfoView/>
            </div>
        </>
    );
}

export default CompareProjects;
