/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import {Line, Bar, ScriptableContext, Pie, Doughnut} from "react-chartjs-2";
import {
  barChartOptions
} from "variables/my_charts.js";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";
import {chartColors} from "./colors";


const SimplePieChart = ({labels, data}) => {

  const options = {
    legend: {
      display: false,
      position: "right"
    },
    elements: {
      arc: {
        borderWidth: 0
      }
    }
  };

  const pieOptions = {
    legend: {
      display: false,
      position: "right",
      legendCallback: function(chart) {
        // Return the HTML string here.
        console.log(chart);
        return [
          <ul>
            <li>z</li>
            <li>zzzz</li>
            <li>ppp</li>
            <li>adasda</li>
          </ul>
        ];
      }
    },
    elements: {
      arc: {
        borderWidth: 0
      }
    }
  };

  const pieData = {
    maintainAspectRatio: false,
    responsive: false,
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors
      }
    ]
  };

  const pieDataNoLabels = {
    maintainAspectRatio: false,
    responsive: false,
    labels: [],
    datasets: [
      {
        data: data,
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors
      }
    ]
  };

  const styles = {
    pieContainer: {
      width: "40%",
      height: "40%",
      top: "50%",
      left: "50%",
      position: "absolute",
      transform: "translate(-50%, -50%)"
    },
    relative: {
      position: "relative",
    }
  };

  return (
      <>
        <div className="chart-area">
          <div style={styles.relative}>
          <Doughnut data={pieData} options={options} />
            <div style={styles.pieContainer}>
              <Pie
                  data={pieDataNoLabels}
                  // options={pieOptions}
              />
            </div>
            <div id="legend" />
          </div>
        </div>
      </>
  );
}

export default SimplePieChart;
