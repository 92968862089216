/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Nav, NavLink as ReactstrapNavLink } from "reactstrap";
import {
  BackgroundColorContext,
  backgroundColors
} from "contexts/BackgroundColorContext";
import SimpleTable from "../tables/SimpleTable";
import PieChart from "../../views/charts/PieChart";
import SimplePieChart from "../../views/charts/SimplePieChart";


function Sidebar(props) {
  const location = useLocation();
  const [elements, setElements] = React.useState([]);
  const [topElements, setTopElements] = React.useState([]);
  const sidebarRef = React.useRef(null);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  let ps;

  React.useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      headers.append('authority', "cryptometheus.com");
      const response = await fetch("https://cryptometheus.com/".concat("api/crypto/projects/light?apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf&timeframe="+props.period),
          {headers: headers});
      // convert the data to json
      const json = await response.json();
      setData(json);
    }
    fetchData()
        .catch(console.error);
  }, [props.period]);

  // React.useEffect(() => {
  //   fetch("https://cryptometheus.com/".concat("api/crypto/dev/activity/top"))
  //       .then(response => response.json())
  //       .then(data => setTopElements(data))
  // }, []);


  function setData(data) {
    console.log("Table: " + data[0].Name);
    setElements(data);
    // setElements(data.slice(0, 10));
  }

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  const linkOnClick = () => {
    document.documentElement.classList.remove("nav-open");
  };
  const {routes, rtlActive, logo} = props;
  let logoImg = null;
  let logoText = null;
  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
          <a
              href={logo.outterLink}
              className="simple-text logo-mini"
              target="_blank"
              onClick={props.toggleSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo"/>
            </div>
          </a>
      );
      logoText = (
          <a
              href={logo.outterLink}
              className="simple-text logo-normal"
              target="_blank"
              onClick={props.toggleSidebar}
          >
            {logo.text}
          </a>
      );
    } else {
      logoImg = (
          <Link
              to={logo.innerLink}
              className="simple-text logo-mini"
              onClick={props.toggleSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo"/>
            </div>
           </Link>
      );
      logoText = (
          <Link
              to={logo.innerLink}
              className="simple-text logo-normal"
              onClick={props.toggleSidebar}
          >
            {logo.text}
          </Link>
      );
    }
  }
  return (
      <BackgroundColorContext.Consumer>
        {({color}) => (
            // <div className="sidebar" data={color} style={{minWidth:'400px'}}>
            <div className="sidebar" data={color}>
              <div className="sidebar-wrapper" ref={sidebarRef} onMouseOver={()=>{props.changeRenderAll(true)}}>
                {/*{logoImg !== null || logoText !== null ? (*/}
                {/*    <div className="logo">*/}
                {/*      {logoImg}*/}
                {/*      {logoText}*/}
                {/*    </div>*/}
                {/*) : null}*/}
                <SimpleTable elements={elements} openLink={props.openLink} period={props.period}/>
              </div>
            </div>
        )}
      </BackgroundColorContext.Consumer>
  );
}

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string
  })
};

export default Sidebar;
