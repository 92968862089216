/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";
import {
    Card, Col, Row
} from "reactstrap";
import InsightsSmallGrid from "./InsightsSmallGrid";

require("./InsightsContainer.css")
const InsightsContainer = ({elements, changeRenderAll}) => {

    const [coreData, setCoreData] = useState([]);
    const [ecosystemData, setEcosystemData] = useState([]);

    React.useEffect(() => {
        let coreData = [];
        let ecosystemData = [];
        elements.forEach(element => {
            if (element && element.Insight) {
                coreData.push({
                    Name: element.Name,
                    Ticker: element.Ticker,
                    WeeklyCoreCommits: element.Insight.WeeklyCoreCommits,
                    WeeklyCoreCommits1MChange: element.Insight.WeeklyCoreCommits1MChange,
                    WeeklyCoreCommits3MChange: element.Insight.WeeklyCoreCommits3MChange,
                    WeeklyCoreDevs: element.Insight.WeeklyCoreDevs,
                    WeeklyCoreDevs1MChange: element.Insight.WeeklyCoreActiveDevs1MChange,
                    WeeklyCoreDevs3MChange: element.Insight.WeeklyCoreActiveDevs3MChange,
                })
                ecosystemData.push({
                    Name: element.Name,
                    Ticker: element.Ticker,
                    WeeklyCoreCommits: element.Insight.WeeklyEcosystemCommits,
                    WeeklyCoreCommits1MChange: element.Insight.WeeklyEcosystemCommits1MChange,
                    WeeklyCoreCommits3MChange: element.Insight.WeeklyEcosystemCommits3MChange,
                    WeeklyCoreDevs: element.Insight.WeeklyEcosystemDevs,
                    WeeklyCoreDevs1MChange: element.Insight.WeeklyEcosystemActiveDevs1MChange,
                    WeeklyCoreDevs3MChange: element.Insight.WeeklyEcosystemActiveDevs3MChange,
                })
            }
        });
        sortData(coreData);
        sortData(ecosystemData);
        setCoreData(coreData);
        setEcosystemData(ecosystemData);
    }, [elements]);

    const sortData = (data) => {
        data.sort((a, b) => b.WeeklyCoreCommits - a.WeeklyCoreCommits);
        let i = 1
        do {
            if (data[i - 1]) {
                data[i - 1].Index = i;
            }
            i++;
        } while (i < data.length + 1);
    }

    return (
        <>
            <div className="insights-container">
            <Row className="row-container">
                <Col className="col-insight">
                    <InsightsSmallGrid elements={coreData} title="Core" img={require("assets/img/arrow.png")}/>
                </Col>
                <Col className="col-insight">
                    <InsightsSmallGrid elements={ecosystemData} title="Ecosystem" img={require("assets/img/rocket.png")} updating={true} changeRenderAll={changeRenderAll}/>
                </Col>
            </Row>
            </div>
        </>

    );
};

export default InsightsContainer;
