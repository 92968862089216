import React from 'react'
import { Dropdown, Input } from 'semantic-ui-react'
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    NavLink,
    UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";

require("./TimeframeDropdown.css")
const options = [
    {
        key: 'All time',
        text: 'All time',
        value: 'All time',
        content: 'All time',
    },
    {
        key: 'Year to date',
        text: 'Year to date',
        value: 'YTD',
        content: 'Year to date',
    },
    {
        key: 'Past 1 Month',
        text: 'Past 1 Month',
        value: 'M1',
        content: 'Past 1 Month',
    },
    {
        key: 'Past 3 Months',
        text: 'Past 3 Months',
        value: 'M3',
        content: 'Past 3 Months',
    },
    {
        key: 'Past 6 Months',
        text: 'Past 6 Months',
        value: 'M6',
        content: 'Past 6 Months',
    },
    {
        key: 'Past 1 Year',
        text: 'Past 1 Year',
        value: 'Y1',
        content: 'Past 1 Year',
    }
]


const TimeframeDropdown = ({changeTimeframe}) => {
    const [text, setText] = React.useState("All time");
    const [activeOption, setActiveOption] = React.useState(options[5]);

    React.useEffect(() => {
        setText(activeOption.text);
        if(changeTimeframe) {
            changeTimeframe(activeOption.value);
        }
    }, [activeOption]);

    function onSelectItem(obj) {
        changeTimeframe(obj.value);
        setData(obj.text);
        // setText(obj.text)
    }

    function setData(data) {
        alert(data);
        setText(data);
    }

    return (
        <div id="timeframe-container">
            <UncontrolledDropdown
                id="timeframe-tooltip"
                nav>
                <DropdownToggle
                    caret
                    color="default"
                    nav
                    onClick={(e) => e.preventDefault()}
                >
                    <div className="photo">
                        <img alt="..." src={require("assets/img/calendar.png")} />
                        {/*<i className="tim-icons icon-calendar-60" />*/}
                    </div>
                    <p className="d-lg-none">Period</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                    {options.map((option) => (
                        <NavLink tag="li" key={option.key}>
                            <DropdownItem key={option.text} className="nav-item" onClick={() => setActiveOption(option)}>{option.text}</DropdownItem>
                        </NavLink>
                    ))}
                </DropdownMenu>
                {/*<p id="dropdown-text">{text}</p>*/}
                <UncontrolledTooltip target="#timeframe-tooltip">
                    Adjust period
                </UncontrolledTooltip>
            </UncontrolledDropdown>
        </div>
    );
}
export default TimeframeDropdown;
