import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import './PricesTable.css';

const PricesTable = ({ name, labels, btcLabels, prices, btcPrices }) => {
  const [oldLabels, setOldLabels] = useState([]);

  useEffect(() => {
    const calculateOldLabels = () =>
        labels.map((label, index) => parseInt(label) - prices.length);
    setOldLabels(calculateOldLabels);
  }, [labels, prices]);

  const generateTable = (start, end) => (
      <Col
          key={start}
          className="priceTable"
      >
        <table className="priceTable">
          <thead>
          <tr>
            <th className="thPrice" colSpan="2">Actual</th>
            <th className="thPrice" colSpan="2">Forecasted</th>
          </tr>
          <tr>
            <th className="thPrice">Year</th>
            <th className="thPrice">{name}</th>
            <th className="thPrice">Year</th>
            <th className="thPrice">BTC</th>
          </tr>
          </thead>
          <tbody>
          {labels.slice(start, end).map((label, index) => (
              <tr key={index}>
                <td className="tdPrice">{oldLabels[start + index]}</td>
                <td className="tdPrice">{"$" + prices[start + index]}</td>
                <td className="tdPrice">{label}</td>
                <td className="tdPrice">{"$" + Math.round(btcPrices[start + index])}</td>
              </tr>
          ))}
          </tbody>
        </table>
      </Col>
  );

  const columns = Array.from({ length: 4 }, (_, index) => {
    const itemsPerColumn = Math.ceil(labels.length / 4);
    const start = index * itemsPerColumn;
    const end = start + itemsPerColumn;
    if(labels.slice(start, end).length > 0)
    return generateTable(start, end);
    else
      return null;
  });

  return <div className="table-container text-center">{columns}</div>;
};

export default PricesTable;
