/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar, ScriptableContext } from "react-chartjs-2";
import {
  basicChart,
  chartOptions
} from "variables/my_charts.js";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";

import LineChart from "views/charts/LineChart.js"
import BarChart from "views/charts/BarChart.js"
import {element, func} from "prop-types";
import ProjectDetails from "./ProjectDetails";
import MultiLineChart from "../views/charts/MultiLineChart"
import Sidebar from "../components/Sidebar/Sidebar";
import {Suspense} from "react";
import InsightsContainer from "./grid/InsightsContainer";
import InsightsLargeGrid from "./grid/InsightsLargeGrid";
import {getPeriod} from '../utils/TimeframeUtil'
import InfoView from "./InfoView";


const LazyCommitsContainer = React.lazy(() => import("./LazyCommitsContainer"));

require("./CommitsContainer.css");


// function CommitsContainer() {
const CommitsContainer = ({renderAll, period, timespan, changeTimespan, changeRenderAll}) => {
    const [firstElements, setFirstElements] = React.useState([]);
    const [nextElements, setNextElements] = React.useState([]);
    const [lastUpdateDateTime, setLastUpdateDateTime] = React.useState(undefined);

    React.useEffect(() => {
        const fetchData = async () => {
            const headers = new Headers();
            headers.append('authority', "cryptometheus.com");
            fetch("https://cryptometheus.com/".concat("api/crypto/projects/lastUpdateDateTime?apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf&v=123456"),
                {headers: headers})
                .then(response => response.text())
                .then(data => setLastUpdateDateTime(data.replaceAll("\"", "")))
        }
        fetchData();
    }, []);

    React.useEffect(() => {
        const fetchData = async () => {
            if ((timespan != 3 && firstElements.length > 0 && firstElements[0].Commits.DailyFrame.Commits.length === 0)) {
                const headers = new Headers();
                headers.append('authority', "cryptometheus.com");
                fetch("https://cryptometheus.com/".concat("api/crypto/dev/activity/first-chunks?apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf&v=123456&timeframe=" + period),
                    {headers: headers})
                    .then(response => response.json())
                    .then(data => setData(data))
            }
        }
        fetchData();

    }, [timespan]);

    React.useEffect(() => {
        const fetchData = async () => {
            let url = timespan === 3 ? "api/crypto/dev/activity/first-chunks/light" : "api/crypto/dev/activity/first-chunks";
            const headers = new Headers();
            headers.append('authority', "cryptometheus.com");
            fetch("https://cryptometheus.com/".concat(url + "?apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf&v=123456&timeframe=" + period),
                {headers: headers})
                .then(response => response.json())
                .then(data => setData(data))
        }
        fetchData();
    }, [period]);

    React.useEffect(() => {
        if(renderAll) {
            if (timespan != 3 && firstElements.length > 0 && firstElements[0].Commits.DailyFrame.Commits.length === 0) {
                const fetchData = async () => {
                    const headers = new Headers();
                    headers.append('authority', "cryptometheus.com");
                    const response = await fetch("https://cryptometheus.com/".concat("api/crypto/dev/activity/next-chunks?apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf&v=123456&timeframe=" + period),
                        {headers: headers});
                    // convert the data to json
                    const json = await response.json();
                    setNextElements(json);
                }
                fetchData();
            }
        }
    }, [renderAll, timespan]);

    React.useEffect(() => {
        if(renderAll) {
            let url = timespan === 3 ? "api/crypto/dev/activity/next-chunks/light" : "api/crypto/dev/activity/next-chunks";
            const fetchData = async () => {
                const headers = new Headers();
                headers.append('authority', "cryptometheus.com");
                const response = await fetch("https://cryptometheus.com/".concat(url + "?apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf&v=123456&timeframe=" + period),
                    {headers: headers});
                // convert the data to json
                const json = await response.json();
                setNextElements(json);
            }
            fetchData();
        }
    }, [renderAll, period]);

    function setData(data) {
        setFirstElements(data);
    }

    return (
        <>
            <Row className="landingPage">
                <div className="title content-center">
                    <h1 className="title">Ranking Top Cryptocurrencies by GitHub Developer Activity</h1>
                </div>
                <div className="lastupdate content-center">
                    Last Updated: {lastUpdateDateTime}
                </div>
            </Row>
            {/*<Row>*/}
            {/*    <InsightsLargeGrid elements={firstElements.concat(nextElements)} changeRenderAll={changeRenderAll}/>*/}
            {/*</Row>*/}
            <div className="card-container">
            <Row>
                <InsightsContainer elements={firstElements.concat(nextElements)} changeRenderAll={changeRenderAll}/>
            </Row>
            <Row>
            <InfoView/>
            </Row>
            {/*<Row className="multi-chart">*/}
            {/*    <MultiLineChart elements={firstElements}/>*/}
            {/*</Row>*/}
            <Row>
                <div className="card-container">
                    {
                        firstElements
                            .map(({
                                      Index,
                                      Name,
                                      Ticker,
                                      Labels,
                                      Data,
                                      ProjectTotalCommits,
                                      Commits,
                                      Repositories,
                                      GitUrl,
                                      NumberOfRepository,
                                      NumberOfContributors,
                                      Languages
                                  }) => (
                                // <LineChart name={Name} labels={Labels} data={Data2} totalCommits={ProjectTotalCommits}/>
                                <ProjectDetails
                                    changeTimespan={changeTimespan}
                                    timespan={timespan}
                                    key={Ticker}
                                    index={Index}
                                    name={Name}
                                    ticker={Ticker}
                                    totalCommits={ProjectTotalCommits}
                                    labelsD={Commits.DailyFrame.Labels}
                                    dataD={Commits.DailyFrame.Commits}
                                    labelsW={Commits.WeeklyFrame.Labels}
                                    dataW={Commits.WeeklyFrame.Commits}
                                    labelsM={Commits.MonthlyFrame.Labels}
                                    dataM={Commits.MonthlyFrame.Commits}
                                    repos={Repositories}
                                    gitUrl={GitUrl}
                                    numberOfRepository={NumberOfRepository}
                                    numberOfContributors={NumberOfContributors}
                                    languages={Languages}
                                    period={period}
                                />
                            ))
                    }
                    {
                        nextElements
                            .map(({
                                      Index,
                                      Name,
                                      Ticker,
                                      Labels,
                                      Data,
                                      ProjectTotalCommits,
                                      Commits,
                                      Repositories,
                                      GitUrl,
                                      NumberOfRepository,
                                      NumberOfContributors,
                                      Languages
                                  }) => (
                                // <LineChart name={Name} labels={Labels} data={Data2} totalCommits={ProjectTotalCommits}/>
                                <ProjectDetails
                                    changeTimespan={changeTimespan}
                                    timespan={timespan}
                                    key={Ticker}
                                    index={Index}
                                    name={Name}
                                    ticker={Ticker}
                                    totalCommits={ProjectTotalCommits}
                                    labelsD={Commits.DailyFrame.Labels}
                                    dataD={Commits.DailyFrame.Commits}
                                    labelsW={Commits.WeeklyFrame.Labels}
                                    dataW={Commits.WeeklyFrame.Commits}
                                    labelsM={Commits.MonthlyFrame.Labels}
                                    dataM={Commits.MonthlyFrame.Commits}
                                    repos={Repositories}
                                    gitUrl={GitUrl}
                                    numberOfRepository={NumberOfRepository}
                                    numberOfContributors={NumberOfContributors}
                                    languages={Languages}
                                    period={period}
                                />
                            ))
                    }
                    {/*<Suspense fallback={<div>Loading...</div>}>*/}
                    {/*    <LazyCommitsContainer period={period} timespan={timespan} changeTimespan={changeTimespan}/>*/}
                    {/*</Suspense>*/}
                </div>
                {/*<div className="title-sub">*/}
                {/*    Analysis does not include generated commits or commits originating from forked repositories.*/}
                {/*</div>*/}
            </Row>
            </div>
                {
                    !renderAll ?
                        <Button
                            tag="label"
                            className="centered-buttons"
                            color="info"
                            onClick={() => changeRenderAll(true)}>
                            Load More
                        </Button>:null
                }
        </>
    );
}

export default CommitsContainer;
