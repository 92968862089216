import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

require("./styles.css");

//https://codesandbox.io/s/8lyp733pj0
class Autocomplete extends Component {
    static propTypes = {
        suggestions: PropTypes.instanceOf(Array),
        callback: PropTypes.func,
        placeholder: PropTypes.any,
        activateOnInputClick: PropTypes.bool,
        width: PropTypes.number,
        className: PropTypes.any,
    };

    static defaultProps = {
        suggestions: [],
        placeholder: "",
        activateOnInputClick: false,
        width: 350,
        className: "autocomplete-input"
    };

    constructor(props) {
        super(props);
        this.state = {
            // The active selection's index
            activeSuggestion: 0,
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            activateAllSuggestions: true,
            userInput: ""
        };
    }

    componentDidUpdate(props) {
        this.state.activateAllSuggestions = false;
        this.state.userInput= "";
    }

    onChange = e => {
        const { suggestions } = this.props;
        let userInput = e.currentTarget.value;
        userInput = userInput.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');

        // Filter our suggestions that don't contain the user's input
        // const filteredSuggestions = suggestions.filter(
        //     suggestion =>
        //         suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        // );
        suggestions.sort( () => .5 - Math.random() );
        const filteredSuggestions = suggestions.filter(s => {
            const regex = new RegExp(`${userInput.toLowerCase()}`, "gi");
            return s.match(regex)
        })
        console.log(filteredSuggestions);

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value
        });
    };

    onInputClick = e => {
        const {suggestions, activateOnInputClick} = this.props;
        if(activateOnInputClick) {
            /*
                    suggestions.sort((a, b) => a.localeCompare(b))
            */
            const filteredSuggestions = suggestions;
            this.setState({
                activeSuggestion: 0,
                filteredSuggestions,
                showSuggestions: true,
                activateAllSuggestions: true,
                userInput: ""
            });
        }
    };

    onClick = e => {
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText
        });
        this.props.callback(e.currentTarget.innerText);

    };

    onKeyDown = e => {
        const { activeSuggestion, filteredSuggestions } = this.state;

        // User pressed the enter key
        if (e.keyCode === 13) {
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion]
            });
            this.props.callback(filteredSuggestions[activeSuggestion]);
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            // let x = filteredSuggestions[filteredSuggestions.length];
            // filteredSuggestions.pop();
            // filteredSuggestions.unshift(x);
            this.setState({activeSuggestion: activeSuggestion - 1, userInput:filteredSuggestions[activeSuggestion - 1] });
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            let x = filteredSuggestions[0];
            filteredSuggestions.shift();
            filteredSuggestions.push(x);
            this.setState({ filteredSuggestions: filteredSuggestions, activeSuggestion: activeSuggestion, userInput:filteredSuggestions[activeSuggestion] });
        }
    };

     getTicker = project =>{
        return project.split("(")[1].split(")")[0];
    }

    render() {
        const {
            onChange,
            onInputClick,
            onClick,
            onKeyDown,
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInput
            }
        } = this;

        let suggestionsListComponent;

        if (showSuggestions && (userInput || this.state.activateAllSuggestions)) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className={"autocomplete-input-controls" === this.props.className
                        ? "suggestions left" : "suggestions"}>
                        {filteredSuggestions.map((suggestion, index) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <li className={className} key={suggestion} onClick={onClick}>
                                    <img className="logo-dropdown" style={{"width": "20px", "margin-right": "10px"}}
                                         alt={suggestion} src={"https://cryptometheus.com/".concat("api/logos/").concat(this.getTicker(suggestion)).concat(".png")}/>
                                    {suggestion}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No suggestions available</em>
                    </div>
                );
            }
        }

        return (
            <Fragment>
                <input
                    className={ "autocomplete-input " + this.props.className}
                    type="text"
                    style={{"width":this.props.width}}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onClick={onInputClick}
                    value={userInput}
                    placeholder={this.props.placeholder}
                />
                {suggestionsListComponent}
            </Fragment>
        );
    }
}

export default Autocomplete;
