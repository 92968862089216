/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar, ScriptableContext } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";
import LanguageLabels from "../labels/LanguagesLabels";
import PricesTable from "./PricesTable";

require("./MultiLineChartV2.css");
const MultiLineChartOil = ({name, ticker, currentPrice}) => {
  const [datasets, setDatasets] = React.useState([]);
  const [labels, setLabels] = useState([]);
  const [prices, setPrices] = useState([]);
  const existingPrices  = [
    1.63, 2.16, 2.77, 2.77, 2.77, 2.77, 2.77, 2.92, 2.99, 2.93, 2.94, 3.14, 3.00,
    3.00, 2.91, 2.85, 2.85, 2.91, 3.00, 3.01, 3.10, 3.12, 3.18, 3.32, 3.39, 3.60,
    3.60, 4.75, 9.35, 12.21, 13.10, 14.40, 14.95, 25.10, 37.42, 35.75, 31.83,
    29.08, 28.75, 26.92, 14.44, 17.75, 14.87, 18.33, 23.19, 20.20, 19.25, 16.75,
    15.66, 16.75, 20.46, 18.64, 11.91, 16.56, 27.39, 23.00, 22.81, 27.69, 37.66,
    50.04, 58.30, 64.20, 91.48, 53.48, 71.21, 87.04, 86.46, 91.17, 85.60, 41.85,
    36.34, 43.97, 57.77, 50.01, 32.25, 60.84, 87.40, 70.58
  ];

  const [comparePrices, setComparePrices] = useState([]);

  const startYear = 1946;
  const endYear = 2024;

  const availableOptions = [1950, 1960, 1970, 1980, 1990, 2000, 2010, 2024];
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [selectedEndOption, setSelectedEndOption] = useState(undefined);
  const [startFrom, setStartFrom] = useState(0);
  const [selectedEndYear, setSelectedEndYear] = useState(endYear);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleEndChange = (event) => {
    setSelectedEndOption(event.target.value);
  };

  useEffect(() => {
    setComparePrices(existingPrices);
  },[]);

  useEffect(() => {
    if (selectedOption) {
      const year = parseInt(selectedOption);
      const startFrom = year - startYear;
      setStartFrom(startFrom);
      if (selectedEndYear) {
        setComparePrices(existingPrices.slice(startFrom, selectedEndYear - startYear))
      } else {
        setComparePrices(existingPrices.slice(startFrom))
      }
    }
  },[selectedOption]);

  useEffect(() => {
    if(selectedEndOption){
      const endY = parseInt(selectedEndOption);
      setSelectedEndYear(endY)
      const end = endY - startYear;
      setComparePrices(existingPrices.slice(startFrom, end))
    }
  }, [selectedEndOption]);

  useEffect(() => {
    const startYear = 2024;
    const endYear = 2024 + comparePrices.length - 1;
    const labels = [];
    for (let year = startYear; year <= endYear; year++) {
      labels.push(`${year}`);
    }
    setLabels(labels);

    const prices = [];
    prices.push(currentPrice);
    comparePrices.forEach((price, index) => {
      if (index > 0) {
        const priceChange = (price - comparePrices[index - 1]) / comparePrices[index - 1];
        currentPrice *= (1 + priceChange);
        prices.push(currentPrice);
      }
    });

    setPrices(prices);
  }, [comparePrices]);

  function normalize(data) {
    let normalizedData = [];

    let min = Math.min(...data);
    let max = Math.max(...data);

    for (let value of data) {
      let normalizedValue = (value - min) / (max - min);
      normalizedData.push(normalizedValue);
    }

    return normalizedData;
  }


  React.useEffect(() => {
    let datasets = []
    let dataset = {
      label: "Crude Oil",
      borderWidth: 1,
      fill: true,
      lineTension: 0.5,
      point: {
        radius: 0,  // Set radius to 0 to hide points
      },
      pointHoverRadius: 0,
      data: normalize(comparePrices).map(x=>x*0.85),
      color: "info",
      borderColor: "rgb(238,232,170)",
      backgroundColor: (context) => {
        const ctx = context.chart.ctx;
        let gradientStroke = ctx.createLinearGradient(0, 230, 0, 350);
        gradientStroke.addColorStop(1, "rgba(0,0,0,0.25)");
        gradientStroke.addColorStop(0.4, "rgba(75,75,75,0.2)");
        gradientStroke.addColorStop(0, "rgba(105,105,105,0.05)");
        return gradientStroke;
      },
    }
    if (prices.length > 0) {
      let dataset2 = {
        label: `${name}`,
        borderWidth: 2,
        fill: true,
        lineTension: 0.5,
        point: {
          radius: 0,  // Set radius to 0 to hide points
        },
        pointHoverRadius: 0,
        data: normalize(prices),
        color: "info",
        borderColor: "rgb(218,165,32)",
        backgroundColor: "rgba(250,250,210, 0.2)",
      }
      datasets.push(dataset2);
      datasets.push(dataset);
    }
    setDatasets(datasets);
  }, [prices]);

  let x = {
    labels: labels,
    datasets: datasets
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            // Customize the tooltip label here
            const labelIndex = context.dataIndex;
            var value = prices[labelIndex];
            var suffix= "";
            if (context.dataset.label.includes("Crude Oil")) {
              value = comparePrices[labelIndex];
              suffix = labels[labelIndex]
              suffix = parseInt(suffix) - comparePrices.length;
            }
            const customLabelLeft = `${suffix} ${context.dataset.label}: ${value.toFixed(2)} USD`;

            return [customLabelLeft];
          },
        },
      },
    },
    animation: {
      duration: 2000, // Set the animation duration in milliseconds
    },
    scales: {
      x: {
        axis: 'time',
        time: {
          unit: 'month',
        },
      },
      x1: {
        position: 'top',
        ticks: {
          callback: (value, index) => {
            return  selectedEndYear - (labels.length - index);
          },
        },
      },
      left: {
        axis: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Crude Oil USD',
        },
        ticks: {
          callback: (value, index) => {
            var min = Math.min(...comparePrices);
            var max = Math.max(...comparePrices);
            if (index === 0) {
              return min.toFixed(2);
            } else if (index === 10) {
              return (max * 1.01).toFixed(2);
            } else {
              const ratio = index / 10;
              return (min + ratio * (max - min)).toFixed(2);
            }
          },
        },
      },
      right: {
        axis: 'linear',
        position: 'right',
        title: {
          display: true,
          text: `${name} USD Price`,
        },
        ticks: {
          callback: (value, index) => {
            var min = Math.min(...prices);
            var max = Math.max(...prices);
            if (index === 0) {
              return min.toFixed(2);
            } else if (index === 10) {
              return (max * 1.01).toFixed(2);
            } else {
              const ratio = index / 10;
              return (min + ratio * (max - min)).toFixed(2);
            }
          },
        },
      },
    },
  };

  return (
      <>
        {datasets.length > 0 ?
            <div className="chart-area multi-line-chart">
              <Card className="card-chart">
                <div className={"crypto-desc"}>
                  <p><img style={{"width": "20px"}} className="project-logo"
                          alt={ticker}
                          src={"https://cryptometheus.com/".concat("api/logos/").concat(ticker).concat(".png")}/>Explore {name}'s ({ticker}) potential future price in 2030, 2040, 2050 and even beyond.
                  </p>
                  <p><strong>Explore a potential scenario: </strong>
                    Using the percentage changes in <strong>Crude Oil</strong> prices since 1946, envision how <strong>{name} ({ticker})</strong> prices could follow a similar trajectory.
                  </p>
                  <p>
                    Dive into the future of crypto with our predictive tools.
                  </p>
                </div>
              </Card>
              <div style={{ display: 'inline-block', marginBottom: '20px'}}>
                <label htmlFor="startYear">Select the time series of <strong>Crude Oil</strong> prices to be considered for projecting the future values of <strong>{name} ({ticker})</strong>, start year :</label>
                <select id="startYear" className="dropdown" value={selectedOption} onChange={handleChange}>
                  <option value="">{selectedOption ? "Select..." : startYear}</option>
                  {availableOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                  ))}
                </select>

                <p style={{ display: 'inline-block', margin: '0' }}> end year:</p>

                <select id="endYear" className="dropdown" value={selectedEndOption} onChange={handleEndChange}>
                  <option value="">{selectedEndOption ? "Select..." : endYear}</option>
                  {availableOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                  ))}
                </select>
              </div>
              <Line
                  data={x}
                  options={chartOptions}
                  width={window.innerWidth < 800 ? window.innerWidth - 330 : window.innerWidth - 360}
                  height={400}
              />
              <PricesTable name={"Crude Oil"}
                           labels={labels.map((label, index) => selectedEndYear + index)}
                           btcLabels={labels}
                           prices={comparePrices}
                           btcPrices={prices}/>
            </div> : null
        }
      </>
  );
}

export default MultiLineChartOil;
