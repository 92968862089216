/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";
import {
    Card, Col, Row
} from "reactstrap";
import {func} from "prop-types";

require("./InsightsLargeGrid.css")
require("../Project.css");

const InsightsLargeGrid = ({elements, changeRenderAll}) => {
    const [data, setData] = useState([]);
    const [spinning, setSpinning] = useState(true);
    const [element, setElement] = useState({});
    const [elementEco, setElementEco] = useState({});

    React.useEffect(() => {
        //let data = elements;
        ///sortData(data);
        setData(elements);
        if(elements && elements.length>0) {
            setElement(elements[0])
            setElementEco(getElementEco(elements))
        }
    }, [elements]);

    function getElementEco(elements){
        return elements.reduce(function (p, v) {
            if(p.Insight == undefined) return v;
            if(v.Insight == undefined) return p;
            return (p.Insight.WeeklyEcosystemCommits > v.Insight.WeeklyEcosystemCommits ? p : v );
        });

    }

    const sortData = (data) => {
        data.sort((a, b) => b.Insight.WeeklyCoreCommits - a.Insight.WeeklyCoreCommits);
        let i = 1
        do {
            if (data[i - 1]) {
                data[i - 1].Index = i;
            }
            i++;
        } while (i < data.length + 1);
    }


    return (
        <div className="large-insights-container" onMouseOver={() => {
            setSpinning(false);
            changeRenderAll(false)
        }}>
            <Card className="insights-card">
                <Row>
                    <Col id="insight-header">
                        <Row>
                        <Col><h2 className="insight-grid-title"><b>Weekly GitHub Stats</b></h2></Col>
                        {spinning === true ?
                            <Col className="insight-grid-title">
                                Updating
                                <img className="small-icon spinning-wheel" alt="..."
                                     src={require("assets/img/icons/spinning.gif")}/>
                            </Col> : null}
                        </Row>
                        <div id="single-project" className="content">
                            {data && data.length>0 ?
                                <div className="project-description">
                                    <Card className="card-chart">
                                        <div className={"project-desc"}>
                                            Top performers:   <br></br> <br></br>
                                            Core: <img style={{"width": "20px"}} className="project-logo"
                                                                              alt={element.Ticker}
                                                                              src={"https://cryptometheus.com/".concat("api/logos/").concat(element.Ticker).concat(".png")}/>  {element.Name}
                                            <br></br>
                                            {elementEco?
                                                <div>
                                            Ecosystem: <img style={{"width": "20px"}} className="project-logo"
                                                            alt={elementEco.Ticker}
                                                            src={"https://cryptometheus.com/".concat("api/logos/").concat(elementEco.Ticker).concat(".png")}/>
                                                    {elementEco.Name} </div>:null}
                                            <br></br>
                                            <img style={{"width": "20px"}} className="project-logo"
                                                 alt={element.Ticker}
                                                 src={"https://cryptometheus.com/".concat("api/logos/").concat(element.Ticker).concat(".png")}/>
                                            {element.Name} demonstrated {element.ProjectTotalCommits > 100 ? "strong" : "modest"} developer activity on
                                            GitHub with <b>{element.ProjectTotalCommits}</b> GitHub commits across <b>{element.NumberOfRepository}</b> core
                                            repositories for the selected
                                            period. This earned the project a ranking of <u><b><font
                                                color={"blue"}>{element.Index}{element.Index%10===1?"st":(element.Index%10===2?"nd":(element.Index%10===3?"rd":"th"))}</font></b></u> place.
                                            Additionally, in the past 7 days,
                                            there {element.Insight.WeeklyCoreDevs == 1 ? "was" : "were"} <b>{element.Insight.WeeklyCoreDevs}</b> core developers who
                                            made <b>{element.Insight.WeeklyCoreCommits}</b> commits
                                            across <b>{element.Insight.CoreRepos}</b> core repositories in GitHub.
                                            The number of commits is on
                                            the {element.Insight.WeeklyCoreCommits1MChange < 0 ? "downtrend" : "uptrend"} with a monthly
                                            change of <font
                                                color={element.Insight.WeeklyCoreCommits1MChange > 0 ? "GREEN" : "RED"}><b>{element.Insight.WeeklyCoreCommits1MChange}%</b></font>.
                                        </div>
                                        {elementEco?
                                        <div className={"project-desc"}><img style={{"width": "20px"}} className="project-logo"
                                                                           alt={elementEco.Ticker}
                                                                           src={"https://cryptometheus.com/".concat("api/logos/").concat(elementEco.Ticker).concat(".png")}/> {elementEco.Name} ecosystem
                                            {elementEco.Insight.EcosystemProjects > 10 ? " continues to flourish" : " is still at an early stage"},
                                            with <b>{elementEco.Insight.EcosystemProjects}</b> {elementEco.Insight.EcosystemProjects == 1 ? "project" : "projects"} building
                                            on its network, with a total of <b>{elementEco.Insight.EcosystemRepos}</b> public GitHub
                                            repositories.
                                            Furthermore, this week, projects building on {elementEco.Name} saw the participation
                                            of <b>{elementEco.Insight.WeeklyEcosystemDevs}</b> individual developers,
                                            a <font
                                                color={elementEco.Insight.WeeklyEcosystemActiveDevs1MChange > 0 ? "GREEN" : "RED"}><b>{elementEco.Insight.WeeklyEcosystemActiveDevs1MChange}%</b></font> change
                                            compared to the previous month.
                                            These developers contributed <b>{elementEco.Insight.WeeklyEcosystemCommits}</b> commits,
                                            which represents
                                            a {elementEco.Insight.WeeklyEcosystemCommits1MChange > 0 ? "increase" : "decrease"} of <font
                                                color={elementEco.Insight.WeeklyEcosystemCommits1MChange > 0 ? "GREEN" : "RED"}><b>{elementEco.Insight.WeeklyEcosystemCommits1MChange}%</b></font>
                                            in contrast to the previous month.
                                        </div>:null}
                                    </Card></div> : null
                            }
                        </div>
                    </Col>
                </Row>
                <table className="project-summary-table table-back">
                    <thead>
                    <tr>
                        <th className="project-summary-table header-back" rowSpan="3">Name</th>
                        <th className="project-summary-table header-back" rowSpan="3">Type</th>
                        <th className="project-summary-table header-back mobile-none" rowSpan="3">Projects</th>
                        <th className="project-summary-table header-back mobile-none" rowSpan="3">Repos</th>
                        <th className="project-summary-table header-back" colSpan="6">Weekly GitHub stats</th>
                    </tr>
                    <tr>
                        <th className="project-summary-table blue-back" colSpan="3">Commits</th>
                        <th className="project-summary-table purple-back" colSpan="3">Developers</th>
                    </tr>
                    <tr>
                        <th className="project-summary-table blue-back" colSpan="1">Count</th>
                        <th className="project-summary-table blue-back" colSpan="1">1M%</th>
                        <th className="project-summary-table blue-back mobile-none" colSpan="1">3M%</th>
                        <th className="project-summary-table purple-back" colSpan="1">Count</th>
                        <th className="project-summary-table purple-back" colSpan="1">1M%</th>
                        <th className="project-summary-table purple-back mobile-none" colSpan="1">3M%</th>
                    </tr>
                    </thead>
                    {
                        data
                            .map(({
                                      Index,
                                      Name,
                                      Ticker,
                                      NumberOfProjects,
                                      NumberOfRepository,
                                      Insight
                                  }) => (
                                <tbody>
                                <tr>
                                    <td className="project-summary-table-name" rowSpan="2" onClick={function () {
                                        window.open("/project/" + Ticker);
                                    }}>
                                        <img style={{"width": "20px"}} className="project-logo"
                                             alt={Ticker}
                                             src={"https://cryptometheus.com/".concat("api/logos/").concat(Ticker).concat(".png")}/>
                                        {Name}
                                    </td>
                                    <th className="project-summary-table2 type">Core</th>
                                    <td className="project-summary-table2 mobile-none">{NumberOfProjects == 0 && NumberOfRepository > 0 ? 1 : NumberOfProjects}</td>
                                    <td className="project-summary-table2 mobile-none">{NumberOfRepository}</td>
                                    <td className="blue-back1">
                                        {Insight != undefined ? Insight.WeeklyCoreCommits: 0}
                                    </td>
                                    <td className="blue-back2"><font
                                        color={Insight != undefined && Insight.WeeklyCoreCommits1MChange > 0 ? "GREEN" : "RED"}>
                                        {Insight != undefined ?
                                            Insight.WeeklyCoreCommits1MChange :
                                            0}%
                                    </font>
                                    </td>
                                    <td className="blue-back1 mobile-none"><font
                                        color={Insight != undefined && Insight.WeeklyCoreCommits3MChange > 0 ? "GREEN" : "RED"}>
                                        {Insight != undefined ?

                                            Insight.WeeklyCoreCommits3MChange : 0}%
                                    </font>
                                    </td>
                                    <td className="purple-back1">
                                        {Insight != undefined ?
                                            Insight.WeeklyCoreDevs : 0}
                                    </td>
                                    <td className="purple-back"><font
                                        color={Insight != undefined && Insight.WeeklyCoreActiveDevs1MChange > 0 ? "GREEN" : "RED"}>
                                        {Insight != undefined ?
                                            Insight.WeeklyCoreActiveDevs1MChange : 0}%</font>
                                    </td>
                                    <td className="purple-back1 mobile-none"><font
                                        color={Insight != undefined && Insight.WeeklyCoreActiveDevs3MChange > 0 ? "GREEN" : "RED"}>
                                        {Insight != undefined ?
                                            Insight.WeeklyCoreActiveDevs3MChange : 0}%
                                    </font>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="project-summary-table2 type">Ecosystem</th>
                                    <td className="project-summary-table2 mobile-none">{
                                        Insight != undefined ? (Insight.EcosystemProjects ==0 && Insight.EcosystemRepos>0?1:Insight.EcosystemProjects) :0}</td>
                                    <td className="project-summary-table2 mobile-none">{Insight != undefined ? Insight.EcosystemRepos:0}</td>
                                    <td className="blue-back">{Insight != undefined ? Insight.WeeklyEcosystemCommits:0}</td>
                                    <td className="blue-back3"><font
                                        color={Insight != undefined && Insight.WeeklyEcosystemCommits1MChange > 0 ? "GREEN" : "RED"}>
                                        {Insight != undefined ?
                                            Insight.WeeklyEcosystemCommits1MChange : 0}%
                                    </font>
                                    </td>
                                    <td className="blue-back mobile-none"><font
                                        color={Insight != undefined && Insight.WeeklyEcosystemCommits3MChange > 0 ? "GREEN" : "RED"}>
                                        {Insight != undefined ?
                                            Insight.WeeklyEcosystemCommits3MChange : 0}%
                                    </font>
                                    </td>
                                    <td className="purple-back">{Insight != undefined ? Insight.WeeklyEcosystemDevs :0 }</td>
                                    <td className="purple-back3"><font
                                        color={Insight != undefined && Insight.WeeklyEcosystemActiveDevs1MChange > 0 ? "GREEN" : "RED"}>
                                        {Insight != undefined ?
                                            Insight.WeeklyEcosystemActiveDevs1MChange : 0}%
                                    </font>
                                    </td>
                                    <td className="purple-back mobile-none"><font
                                        color={Insight != undefined && Insight.WeeklyEcosystemActiveDevs3MChange > 0 ? "GREEN" : "RED"}>
                                        {Insight != undefined ?
                                            Insight.WeeklyEcosystemActiveDevs3MChange : 0}%
                                    </font>
                                    </td>
                                </tr>
                                </tbody>
                            ))}
                </table>
            </Card>
        </div>
    );
};

export default InsightsLargeGrid;
