import React from 'react'
import {
    Row,
    Col
} from "reactstrap";
import Article1 from "./Article1";
import Article2 from "./Article2";
import Article3 from "./Article3";
import Article4 from "./Article4";

import Thumbnail from "./Thumbnail";

import github from "assets/img/articles/github.png";
import developer from "assets/img/articles/developer.png";
import crypto from "assets/img/articles/crypto.png";
import pop from "assets/img/articles/pop.png";

import {useLocation} from "react-router-dom";

require("./Articles.css")

const Articles = () => {
    const location = useLocation();
    const [activeArticle, setActiveArticle] = React.useState(1);

    React.useEffect(() => {
        const pathParams = location.pathname.split("/");
        let route="";
        if(pathParams.length>2){
            route = pathParams[2].toLowerCase();
        }
        switch (route) {
            case 'what_to_consider_when_investing_in_cryptocurrencies':
                setActiveArticle(1);
                break;
            case 'the_importance_of_developer_community_for_a_crypto_project':
                setActiveArticle(2);
                break;
            case 'the_importance_of_github_commits_in_cryptocurrency_analysis':
                setActiveArticle(3);
                break;
            case 'measuring_the_popularity_of_cryptocurrencies':
                setActiveArticle(4);
                break;
            default:
                setActiveArticle(1);
        }
    },[]);

    return (
        <>
            <div id="articles" className="content">
                <Row>
                    <Col>
                        <Thumbnail image={crypto} large={activeArticle===1} text1="What to Consider When" x1={70} y1={80}
                                   text2="Investing in Cryptocurrencies?" x2={40} y2={110}
                                   onClick={()=>setActiveArticle(1)}/>
                    </Col>
                    <Col>
                        <Thumbnail image={developer} large={activeArticle===2} text1="Why Developer Community" x1={50} y1={80}
                                   text2=" is essential for a cryptocurrency?" x2={35} y2={110}
                                   onClick={()=>setActiveArticle(2)}/>
                    </Col>
                    <Col>
                        <Thumbnail image={github} large={activeArticle===3} text1="What is" x1={activeArticle === 3 ? 140:130} y1={activeArticle === 3 ? 90 :80}
                                   text2="GitHub?" x2={activeArticle === 3 ? 140: 130} y2={activeArticle === 3 ? 115: 105}
                                   onClick={()=>setActiveArticle(3)}/>
                    </Col>
                    <Col>
                        <Thumbnail large={activeArticle===4} image={pop} text1="How to assess the popularity" x1={activeArticle === 4 ? 60 : 50} y1={80}
                                   text2="of a cryptocurrency?" x2={activeArticle === 4 ? 90 : 80} y2={110}
                                   onClick={()=>setActiveArticle(4)}/>
                    </Col>
                </Row>
                {
                    {
                        1: <Article3/>,
                        2: <Article1/>,
                        3: <Article2/>,
                        4: <Article4/>,
                    }[activeArticle]
                }
            </div>
        </>
    )
}

export default Articles;