/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar, ScriptableContext } from "react-chartjs-2";
import {
  barChartOptions
} from "variables/my_charts.js";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";

require("./charts.css")

const BarChart = ({name, labels, data, totalCommits, fullscreen, periodLabel}) => {

  let x = {
    labels: labels,
    datasets: [
      {
        label: name + " - " + periodLabel + " commits",
        fill: true,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
          gradientStroke.addColorStop(1, "rgba(29,140,248,0.25)");
          gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.02)");
          gradientStroke.addColorStop(0, "rgba(29,140,248,0.01)"); //blue colors

          return gradientStroke;


        },
        hoverBackgroundColor: (context) => {
          const ctx = context.chart.ctx;
          let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
          gradientStroke.addColorStop(1, "rgba(29,140,248,0.3)");
          gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.1)");
          gradientStroke.addColorStop(0, "rgba(29,140,248,0.02)"); //blue colors
          return gradientStroke;
        },
        // borderColor: "#d048b6", purple
        // borderColor: "#1f8ef1", blue
        // borderColor: "#00d6b4", green
        borderColor: "#1f8ef1",
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        data: data
      }
    ]
  };

  function getChartWidth(windowWidth) {
    // alert(windowWidth)
    if (windowWidth < 1000) {
      return windowWidth - 20;
    }
    return windowWidth - 350;
  }

  return (
      <>
        <div className="project-chart-area">
          {fullscreen ?
              <Bar
                  data={x}
                  options={barChartOptions}
                  width={window.innerWidth < 800 ? window.innerWidth - 30 : window.innerWidth - 350}
                  height={230}
              /> :
              <Bar
                  data={x}
                  options={barChartOptions}
                  // width={getChartWidth(window.innerWidth)}
                  height={230}
              />}
        </div>
      </>
  );
}

export default BarChart;
