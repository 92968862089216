/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes



import MultiLineChartV2 from "../charts/MultiLineChartV2";
import CryptoMatrix from "./PriceMatrix";
import ForecastLineChart from "../charts/ForecatLineChart";


const PriceToCommit = ({project, info, displayMatrix}) => {
    const [elements, setElements] = React.useState([]);
    const [labels, setLabels] = React.useState([]);
    const [statsMap, setStatsMap] = React.useState([]);
    const [data, putData] = React.useState([]);

    React.useEffect(() => {
        if (info) {
            setData(info)
            return;
        }
        if (project) {
            const headers = new Headers();
            headers.append('authority', "cryptometheus.com");
            fetch("https://cryptometheus.com/".concat("api/crypto/base/project?key=" + encodeURIComponent(project) +
                    "&apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf&v=1234"),
                {headers: headers})
                .then(response => response.json())
                .then(data => setData(data))
                .catch(error => console.error(error));
        }
    }, [project, info]);


    function setData(data) {
        putData(data)
        setStatsMap(data.StatsMap);
        var element1 = {};
        var values1 = data.Commits;
        element1.Values = values1;
        element1.Name = project + "-" + "Commits";

        var element2 = {};
        var values2 = data.Price;
        element2.Values = values2;
        element2.Name = project + "-" + "Price (USD)";

        if ((element1.Values && element1.Values.length > 0) ||
            (element2.Values && element2.Values.length > 0)) {
            setElements([element1, element2])
        }
        setLabels(data.Labels);
    }


    return (

        <div className="chart-area">
            {elements && elements.length > 0 ?
                <>
                    <h4 className="header-project-details">
                        The following multi-line chart visualizes data on Github commits and USD price for {project}.
                    </h4>
                    <MultiLineChartV2 elements={elements} labels={labels}/>
                    <p>
                        Analyzing the chart, you can observe any potential correlation between the number of commits and the USD price for {project}.
                        It's important to note that correlation does not imply causation, but patterns in the data may reveal interesting insights.
                    </p>
                    {displayMatrix?
                        <CryptoMatrix project={project} statsMap={statsMap} />:
                        null
                    }
                </>: null
            }
        </div>

    );
}

export default PriceToCommit;
