/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar, ScriptableContext } from "react-chartjs-2";
import {
  barChartOptions
} from "variables/my_charts.js";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col, Table
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";
import ProjectDetails from "../../views/ProjectDetails";

require("./ReposTable.css");
const ReposTable = ({elements, gitUrl}) => {
  return (
      <>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Top repositories</CardTitle>
          </CardHeader>
          <CardBody>
            <Table className="tablesorter overflow-hidden" responsive>
              <thead className="text-primary">
              <tr>
                <th>Repo Name</th>
                <th>Commits</th>
              </tr>
              </thead>
              <tbody>
              {
                elements.map(({Name, TotalCommits, Ownership, SourceUrl}) => (

                    <tr key={Name}>
                      <td>
                        <a target="_blank" href={gitUrl+"/"+Name}>{Name}</a>
                        {(Ownership !== undefined && Ownership!=="Owned")?
                            <p>
                            <a id="sourceRepo" target="_blank" href={SourceUrl}>{Ownership}</a>
                            </p>
                          :null
                        }
                        </td>
                      <td>{TotalCommits}</td>
                    </tr>
                ))
              }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </>
  );
}

export default ReposTable;
