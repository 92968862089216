/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar, ScriptableContext } from "react-chartjs-2";
import {
  chartOptions
} from "variables/my_charts.js";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";
import LanguageLabels from "../labels/LanguagesLabels";


const MultiLineChart = ({elements, periodLabel}) => {
  const [datasets, setDatasets] = React.useState([]);
  const [labels, setLabels] = React.useState([]);

  React.useEffect(() => {
    let datasets = []
    let index = 0;
    elements.forEach((element) => {
     element.Index = index;
         index++;
    });
    elements.forEach((element) => {
      let r = Math.floor(Math.random() * 155) + 1;
      let g = Math.floor(Math.random() * 155) + 1;
      let b = Math.floor(Math.random() * 155) + 1;
      switch (element.Index){
        case 0:
          r=31;
          g=142;
          b=241;
          break;
        case 1:
          r=208;
          g=72;
          b=182;
          break;
        case 2:
          r=66;
          g=134;
          b=121;
          break;
      }
      let dataset = {
        label: element.Name + " - " + periodLabel + " commits",
        fill: true,
        lineTension: 0.5,
        data: element.Commits,
        color: "info",
        borderColor: "rgb("+r+","+g+","+b+")",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          switch (element.Index){
            case 0:
              r=29;
              g=140;
              b=240;
              break;
            case 1:
              r=122;
              g=72;
              b=176;
              break;
            case 2:
              r=0;
              g=214;
              b=180;
              break;
          }
          let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
          gradientStroke.addColorStop(1, "rgba("+r+","+g+","+b+",0.3");
          gradientStroke.addColorStop(0.4, "rgba("+r+","+g+","+b+",0.15)");
          gradientStroke.addColorStop(0, "rgba("+r+","+g+","+b+",0.02)");
          return gradientStroke;
        },
      }
      datasets.push(dataset);
    });
    setDatasets(datasets);
    if (elements.length > 0) {
      setLabels(elements[0].Labels)
    }
  }, [elements]);

  let x = {
    labels: labels,
    datasets: datasets
  };

  return (
      <>
        {datasets.length > 0 ?
            <div className="chart-area ">
              {/*<Row className="center">*/}
              {/*  Top 10 crypto projects by development activity*/}
              {/*</Row>*/}
              <Line
                  data={x}
                  options={chartOptions}
                  width={window.innerWidth < 800 ? window.innerWidth - 30 : window.innerWidth - 80}
                  height={400}
              />
            </div> : null
        }
      </>
  );
}

export default MultiLineChart;
