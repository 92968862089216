
export const getPeriod = (timeframe, timespan) => {
    let span = "monthly";
    switch (timespan) {
        case 1:
            span = "daily";
            break;
        case 2:
            span = "weekly";
            break;
    }

    let period={};
    switch (timeframe) {
        case "Y1":
            period.label = "1Y";
            period.longName = "past 1 year - " + span;
            break;
        case "YTD":
            period.label = "YTD";
            period.longName = "year to date - " + span;
            break;
        case "All time":
            period.label = ">1Y";
            period.longName = "past 1 year - " + span;
            break;
        case "M1":
            period.label = "1M";
            period.longName = "past 1 month - " + span;
            break;
        case "M3":
            period.label = "3M";
            period.longName = "past 3 months - " + span;
            break;
        case "M6":
            period.label = "6M";
            period.longName = "past 6 months - " + span;
            break;
    }
    return period;
};