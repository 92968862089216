/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import Project from "views/Project.js";

import routes from "routes.js";

// import logo from "assets/img/cup.jpeg";
import logo from "assets/img/logos/logo3.png";

import { BackgroundColorContext } from "contexts/BackgroundColorContext";

import Dashboard from "../../views/Dashboard";
import CompareProjects from "../../views/compare/CompareProjects";
import Articles from "../../views/articles/Articles";
import DeveloperReportContainer from "views/DeveloperReportContainer.js"
import TokenomicsContainer from "../../views/tokenomics/TokenomicsContainer";
import Crypto from "../../views/price/Crypto";

var ps;

require("./Admins.css");

function Admin(props) {
  const location = useLocation();
  const mainPanelRef = React.useRef(null);
  const [isDefaultPage, setIsDefaultPage] = React.useState(true);
  const [timeframe, setTimeframe] = React.useState('Y1');
  const [timespan, setTimespan] = React.useState(3);
  const [renderAll, setRenderAll] = React.useState(false);
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );

  function changeTimeframe(timeframe) {
    setTimeframe(timeframe);
    // if(timeframe !== "All time" && timeframe !== "Y1") {
    //   setTimespan(1);
    // }
    switch (timeframe) {
      case "All time":
      case "Y1":
        setTimespan(3);
        break;
      case "YTD":
      case "M1":
      case "M3":
        setTimespan(1);
        break
      case "M6":
        setTimespan(2);
        break;
    }
  }

  function changeRenderAll(value){
    if(value) {
      setRenderAll(value);
    }
  }
  function changeTimespan(timespan){
    setTimespan(timespan);
  }

  React.useEffect(() => {
    if(! (location.pathname === "/")){
      setIsDefaultPage(false);
    }
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current, {
        suppressScrollX: true
      });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor }) => (
        <React.Fragment>
          <div className="wrapper">
            {
              // we don't want the Footer to be rendered on map page
              location.pathname === "/" ||
              location.pathname === "/list" ||
              location.pathname.startsWith("/articles") ||
              location.pathname.startsWith("/project") ||
              location.pathname.startsWith("/report") ||
              location.pathname.startsWith("/tokenomics")
                  ?
                  <Sidebar
                      period={timeframe}
                      changeRenderAll={changeRenderAll}
                      routes={routes}
                      logo={{
                        innerLink: "/",
                        // text: "Top Projects",
                        // text: "Ranking Coins",
                        text: "Commit Hierarchy",
                        imgSrc: logo
                      }}
                      toggleSidebar={toggleSidebar}
                      openLink={location.pathname.startsWith("/articles") ||
                          location.pathname.startsWith("/project") ||
                          location.pathname.startsWith("/report")}
                  /> : null
            }
            <div className="main-panel" ref={mainPanelRef} data={color}>
              <AdminNavbar
                  handleTimeframeChange={changeTimeframe}
                  handleTimespanChange={changeTimespan}
                brandText={"CryptoWatcher"}
                toggleSidebar={toggleSidebar}
                sidebarOpened={sidebarOpened}
              />


              {(() => {
                const pathParams = location.pathname.split("/");
                const route = pathParams[1];
                switch (route) {
                  case '':
                    return <Dashboard renderAll={renderAll} timeframe={timeframe} timespan={timespan} changeTimespan={changeTimespan} changeRenderAll={changeRenderAll}/>
                  case 'list':
                    setRenderAll(true);
                    return <Dashboard renderAll={renderAll} timeframe={timeframe} timespan={timespan} changeTimespan={changeTimespan} changeRenderAll={changeRenderAll}/>
                  case 'project':
                    setTimespan(1);
                    return <Project timeframe={timeframe} timespan={timespan}/>
                  case 'crypto':
                    return <Crypto/>
                  case 'compare':
                    return <CompareProjects timeframe={timeframe} timespan={timespan}/>
                  case 'articles':
                    return <Articles/>
                  case 'report':
                    return <DeveloperReportContainer timeframe={timeframe} timespan={timespan}/>
                  case 'tokenomics':
                    return <TokenomicsContainer/>
                  default:
                    return <Dashboard renderAll={renderAll} timeframe={timeframe} timespan={timespan} changeTimespan={changeTimespan} changeRenderAll={changeRenderAll}/>
                }
              })()}
              {
                // we don't want the Footer to be rendered on map page
                location.pathname === "/admin/maps" ? null : <Footer fluid />
              }
            </div>
          </div>
          <FixedPlugin bgColor={color} handleBgClick={changeColor}/>
        </React.Fragment>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Admin;
