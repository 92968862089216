/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar, ScriptableContext } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";
import LanguageLabels from "../labels/LanguagesLabels";

require("./MultiLineChartV2.css");
const MultiLineChartV2 = ({elements, labels}) => {
  const [datasets, setDatasets] = React.useState([]);

  function normalize(data) {
    let normalizedData = [];

    let min = Math.min(...data);
    let max = Math.max(...data);

    for (let value of data) {
      let normalizedValue = (value - min) / (max - min);
      normalizedData.push(normalizedValue);
    }

    return normalizedData;
  }

  React.useEffect(() => {
    let datasets = []
    let index = 0;
    elements.forEach((element) => {
     element.Index = index;
         index++;
    });
    elements.forEach((element) => {

      let r = Math.floor(Math.random() * 155) + 1;
      let g = Math.floor(Math.random() * 155) + 1;
      let b = Math.floor(Math.random() * 155) + 1;
      switch (element.Index){
        case 0:
          r=31;
          g=142;
          b=241;
          break;
        case 1:
          r = 208;  // Set red value
          g = 72;
          b = 182;
          break;
        case 2:
          r=66;
          g=134;
          b=121;
          break;
      }
      let dataset = {
        label: element.Name,
        borderWidth: element.Index == 0 ? 2 : 1,
        fill: true,
        lineTension: 0.5,
        point: {
          radius: 0,  // Set radius to 0 to hide points
        },
        pointHoverRadius: 0,
        data: normalize(element.Values),
        color: "info",
        borderColor: "rgb("+r+","+g+","+b+")",
        backgroundColor: element.Index === 1
            ? "rgba(0, 0, 0, 0)"  // Transparent background for Index 0
            : (context) => {
              const ctx = context.chart.ctx;
              switch (element.Index) {
                case 0:
                  r = 29;
                  g = 140;
                  b = 240;
                  break;
                case 1:
                  r = 0;
                  g = 0;
                  b = 0;
                  break;
                case 2:
                  r = 0;
                  g = 214;
                  b = 180;
                  break;
              }
              let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
              gradientStroke.addColorStop(1, "rgba(" + r + "," + g + "," + b + ",0.3)");
              gradientStroke.addColorStop(0.4, "rgba(" + r + "," + g + "," + b + ",0.2)");
              gradientStroke.addColorStop(0, "rgba(" + r + "," + g + "," + b + ",0.05)");
              return gradientStroke;
            },
      }
      datasets.push(dataset);
    });
    setDatasets(datasets);
  }, [elements]);

  let x = {
    labels: labels,
    datasets: datasets
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            // Customize the tooltip label here
            const labelIndex = context.dataIndex;
            var value= elements[0].Values[labelIndex];
            if (context.dataset.label.includes("Price")) {
              value= elements[1].Values[labelIndex]
            }
            const customLabelLeft = `${context.dataset.label}: ${value.toFixed(2)}${context.dataset.label.includes("Price") ? " USD" : ""}`;

            return [customLabelLeft];
          },
        },
      },
    },
    animation: {
      duration: 2000, // Set the animation duration in milliseconds
    },
    scales: {
      x: {
        axis: 'time',
        time: {
          unit: 'month',
        },
      },
      left: {
        axis: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Commits',
        },
        ticks: {
          callback: (value, index) => {
            var min = Math.min(...elements[0].Values);
            var max = Math.max(...elements[0].Values);
            if (index === 0) {
              return min.toFixed(2);
            } else if (index === 10) {
              return (max * 1.01).toFixed(2);
            } else {
              const ratio = index / 10;
              return (min + ratio * (max - min)).toFixed(2);
            }
          },
        },
      },
      right: {
        axis: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'USD Price',
        },
        ticks: {
          callback: (value, index) => {
            var min = Math.min(...elements[1].Values);
            var max = Math.max(...elements[1].Values);
            if (index === 0) {
              return min.toFixed(2);
            } else if (index === 10) {
              return (max * 1.01).toFixed(2);
            } else {
              const ratio = index / 10;
              return (min + ratio * (max - min)).toFixed(2);
            }
          },
        },
      },
    },
  };

  return (
      <>
        {datasets.length > 0 ?
            <div className="chart-area multi-line-chart">
              {/*<Row className="center">*/}
              {/*  Top 10 crypto projects by development activity*/}
              {/*</Row>*/}
              <Line
                  data={x}
                  options={chartOptions}
                  width={window.innerWidth < 800 ? window.innerWidth - 330 : window.innerWidth - 360}
                  height={400}
              />
            </div> : null
        }
      </>
  );
}

export default MultiLineChartV2;
