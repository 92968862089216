import React from 'react'
import {Card, CardHeader, Col, Row} from "reactstrap";
import info from "assets/img/icons/info.png";
import moon from "../assets/img/icons/moon.png";
import sun from "../assets/img/icons/sun.png";

require("./InfoView.css")
const InfoView = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <>
            <div id="info">
                <Card>
                    <Row>
                        <Col xs={1}>
                            <img className="info-img" src={info} alt="info-logo"/>
                        </Col>
                        <Col xs={10}>
                            <h2 className="info-view">
                                The development metrics presented on this platform rely on data gathered from GitHub
                                repositories and are furnished solely for informational intent.
                                While we strive to provide accurate and up-to-date information, we cannot guarantee the
                                completeness, reliability, or accuracy of the data.
                                It's important to keep in mind that the crypto industry is constantly evolving, and what
                                may
                                be considered the most developed project today may not be in the future.
                                We strongly advise you to conduct your own research and due diligence before investing
                                in
                                any cryptocurrency project
                                <div className="details-button" onClick={
                                    () => {
                                        isOpen ? setIsOpen(false) : setIsOpen(true);
                                    }
                                }>{isOpen ? "Close details" : "More details"}</div>
                            </h2>
                        </Col>
                    </Row>
                    {isOpen ?
                        <div className="info-view sub-info-view">
                            <h1 className="info-text">
                                By aggregating data collected from GitHub repositories into a single page,
                                it is now possible to assess and compare the development activity of various
                                blockchains.
                            </h1>

                            <h1 className="info-text underline">
                                Understanding Cryptometheus GitHub developer metrics
                            </h1>
                            <p>
                                Cryptocurrency development has grown continuously over the years and holds its presence
                                significantly in open source development.
                                Online collaborative software development platforms such as GitHub present an
                                opportunity to
                                observe developer effort, activity and software growth.
                                Cryptometheus helps you measure that activity but it's up to you whether this approach
                                merits
                                inclusion in your crypto arsenal.
                            </p>
                            <br/>
                            <p className="italic"><b>
                                Below Q&A section will explain our developer metrics clearly so you can make better use
                                of
                                the data on our website.
                            </b>
                            </p>
                            <br/>
                            <br/>
                            <h2 className="info-text">
                                Why is development activity important?
                            </h2>
                            <p>
                                The backbone of any successful chain is the strength of the developer team. Without
                                developers,
                                there will not be any coin or token. Developers are there to create new features and fix
                                defects
                                that can diminish the utility of the chain.
                            </p>
                            <br/>
                            <h2 className="info-text">
                                What is GitHub?
                            </h2>
                            <p>
                                GitHub remains the most popular online resource used by open-source developers. GitHub's
                                role is not
                                particularly linked with cryptocurrencies, in fact, it is nothing more than a public,
                                easy to use
                                and fully-featured source code repository.
                            </p>
                            <p>
                                A lot of data is available on Github and Cryptometheus is
                                automatically doing first level base analytics on that data. By aggregating data
                                collected from GitHub into a single page,
                                it is now possible to assess and compare the development activity of various coins.
                            </p>
                            <br/>
                            <h2 className="info-text">
                                Why is GitHub activity important?
                            </h2>
                            <p>
                                GitHub activity refers to the contributions of developers to open-source
                                crypto projects. The theory is that the more developers that are working on a
                                cryptocurrency
                                project,
                                the more features and improvements can be expected — and the more people believe in the
                                future
                                success of the
                                cryptocurrency. Thus, the chances are higher that a project with high development
                                activity will be
                                successful. All this can have
                                a positive multiplier effect on the value of the project’s currency or token.
                                <br/>
                                Although metrics such as lines of code and commit count
                                do not necessarily speak to software quality,
                                developers use them as signals of
                                popularity and engagement on GitHub, and research suggests
                                that popularity is positively associated with cryptocurrency
                                prices.

                            </p>
                            <br/>
                            <h2 className="info-text">
                                What is a commit?
                            </h2>
                            <p>
                                A commit is an approved change to a project’s source code that the developer community
                                agree is an
                                improvement. A commit may contain 1 single line or 10000 depending
                                on how the developer works, but it is a valid representation of the work actually done.
                                GitHub commits can be a way to measure the activity and development of a cryptocurrency
                                project, and can also be used as an indicator of the popularity of a project within the
                                developer community.
                                A large number of commits on a project's GitHub repository can indicate that the project
                                is being actively developed, and that there is a strong community of developers working
                                on it.
                            </p>
                            <div className="details-button" onClick={() => {
                                setIsOpen(false)
                            }}>Close details
                            </div>
                        </div> : null
                    }
                </Card>
            </div>
        </>
    )
}

export default InfoView;