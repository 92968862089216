/*!

=========================================================
* Cryptometheus v1.0.0
=========================================================

* Copyright 2022 Prometheus

* Coded by Cryptometheus

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes

import ForecastLineChart from "../charts/ForecatLineChart";
import PriceToCommit from "./PriceToCommit";
import {useHistory, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import {Card} from "reactstrap";
import MultiLineChartGold from "../charts/MultiLineChartGold";
import MultiLineChartSP500 from "../charts/MultiLineChartSP500"
import MultiLineChartOil from "../charts/MultiLineChartOil";
import CryptoMatrix from "./PriceMatrix";
import Autocomplete from "../../components/dropdown/Autocomplete";
import {getPeriod} from "../../utils/TimeframeUtil";
require("./Crypto.css")
const Crypto = () => {
    const location = useLocation();
    const [project, setProject] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [allProjects, setAllProjects] = React.useState([]);
    const [active, setActive]  = React.useState(false);

    React.useEffect(() => {
        const fetchData = async () => {
            const headers = new Headers();
            headers.append('authority', "cryptometheus.com");
            const response = await fetch("https://cryptometheus.com/".concat("api/crypto/projects/names?apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf"),
                {headers: headers});
            // convert the data to json
            const json = await response.json();
            setAllProjects(json);
        }
            fetchData()
                .catch(console.error);
    }, [active]);

    React.useEffect(() => {
        const pathParams = location.pathname.split("/");
        const p = pathParams.length > 1 ? pathParams[2] : undefined;
        setProject(p);
        if (p) {
            const headers = new Headers();
            headers.append('authority', "cryptometheus.com");
            fetch("https://cryptometheus.com/".concat("api/crypto/base/project?key=" + encodeURIComponent(p) +
                    "&apiKey=413c99eb-3c3e-4f9f-9d10-8f8b283c3bdf&v=1234"),
                {headers: headers})
                .then(response => response.json())
                .then(data => putData(data))
                .catch(error => console.error(error));
        }
    }, []);

    const putData = (data) => {
        setData(data)
    }

    const getTicker = project => {
        return project.split("(")[1].split(")")[0];
    }
    return (
        <>
            {data ?
                <div className={"crypto-container"}>
                    <Helmet>
                        <title>{"Cryptometheus | Unleash the Future Price Predictions for " + project + " in 2025, 2030, and Beyond!"}</title>
                        <meta name="description" content={"Unlock the Future with Cryptometheus: Explore Price Predictions for " + project + ". Gain insights into potential prices in 2025, 2030, and beyond. Stay ahead of the curve in the world of cryptocurrency."}/>
                        <meta name="keywords"
                              content={project + ", " + data.Name+ ", forecast, price prediction, gold price, spx, S&P500, oil price, gold vs bitcoin, bitcoin ETF"}/>
                        <meta name="twitter:description"
                              content={project + ", " + data.Name+ ", forecast, price prediction, gold price, spx, S&P500, oil price, gold vs bitcoin, bitcoin ETF"}/>
                        <meta property="og:image" content={"https://cryptometheus.com/api/logos/" + project + ".png"}/>
                    </Helmet>
                    <Autocomplete
                        className="autocomplete-input-controls first"
                        suggestions={allProjects}
                        callback={(x) => {
                            window.location.href = `/crypto/${getTicker(x)}`;
                        }}
                        placeholder={"Change the crypto project"}
                        activateOnInputClick={true}
                    />
                    <h2 className={"project-desc"}><img style={{"width": "20px"}} className="project-logo"
                                                        alt={project}
                                                        src={"https://cryptometheus.com/".concat("api/logos/").concat(project).concat(".png")}/>
                        {data.Name} ({data.Ticker}) Price Predictions</h2>
                    <Card className="card-chart">
                        <div className={"crypto-desc"}>
                            <p>Curious about the future value of {data.Name} ({data.Ticker}) in the years ahead? Explore projections for
                                2024, 2025, 2030, and even 2050.
                                Stay informed about potential trajectory of {data.Name}'s ({data.Ticker}) value in the coming years.
                            </p>
                            <br></br>
                            <p>Please note that the data presented here is based on user input and does not reflect the
                                opinion of Cryptometheus.
                            </p>
                            <br></br>
                            <p>
                                The information provided here is for general informational purposes only.
                                It does not constitute financial advice, and users should exercise caution and conduct
                                their own research before making any investment decisions.
                                The app does not guarantee the accuracy or reliability of predictions, and
                                cryptocurrency investments involve inherent risks.
                            </p>
                        </div>
                    </Card>
                    <ForecastLineChart name={data.Name} ticker={data.Ticker} statsMap={data.StatsMap}></ForecastLineChart>
                    {data.Price ?
                        <>
                            <MultiLineChartGold name={data.Name} ticker={data.Ticker} currentPrice={data.Price[data.Price.length - 1]}/>
                            <MultiLineChartSP500 name={data.Name} ticker={data.Ticker} currentPrice={data.Price[data.Price.length - 1]}/>
                            <MultiLineChartOil name={data.Name} ticker={data.Ticker} currentPrice={data.Price[data.Price.length - 1]}/>
                        </> : null
                    }
                    <PriceToCommit project={project} info={data} displayMatrix={false}/>
                    <a href={`https://cryptometheus.com/project/${project}`} rel="canonical">Explore in-depth insights into the developer activity of {data.Name} ({project})</a>
                </div> : null}
        </>

    );
}

export default Crypto;
